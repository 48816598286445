import * as THREE from 'three';
import SceneSubject from '../sceneSubject';
import * as Util from '../../utilities/utilities';
import { lerp } from '../../utilities/utilities';

export default class AsciiLetters extends SceneSubject {

  rate = 0.2;
  index = 0;
  scale = 1;
  positiveText = `what class of people deserves this collapse of superstates the only way to save everyone is to reboot the system and give this may be why people have such a strong feeling of recurrence did you hear them speak of freedom libery prophesy magic bow rain save`;
  negativeText = `bothered by repetition deja vu rokos asphalt basilisk bank the unbanked systemic financial terminology day week month year ambition pleasure broken cart does jamais I would rather ride a horse repair I may have been the harbinger of life she didn't recognize me mount oceans`;

  constructor(scene, scale) {
    super(scene);
    this.scale = scale || 1;
    const loader = new THREE.FontLoader();
    loader.load( './fonts/helvetiker_regular.typeface.json',  ( font ) => {
      console.log(font);
      this.font = font;
      this.glyphs = Object.keys(font.data.glyphs);

      this.color = new THREE.Color(0x006699);
      const mat = new THREE.MeshBasicMaterial( {
        color: this.color,
        transparent: true,
        opacity: 0.25,
        side: THREE.DoubleSide
      } );

      const message = this.glyphs[Math.floor(this.glyphs.length * Math.random())];
      const shapes = font.generateShapes( message, 100 );
      const geometry = new THREE.ShapeGeometry( shapes );
      // this.object3D = new THREE.TextGeometry( text, parameters );
      // this.scene.add(this.object3D);
      geometry.computeBoundingBox();

      const xMid = - 0.5 * ( geometry.boundingBox.max.x - geometry.boundingBox.min.x );

      geometry.translate( xMid, 0, 0 );

      // make shape ( N.B. edge view not visible )

      const text = new THREE.Mesh( geometry, mat );
      text.position.z = - 150;
      this.scene.add( text );
      this.object3D = text;
      this.trigger = 0;
    });
  }

  changeGeometry() {
    if (!this.object3D?.geometry) return;
    this.object3D.geometry.dispose();
    let message = this.glyphs[Math.floor(this.glyphs.length * Math.random())];
    const setText = (array) => {
      let split = array.split(" ");
      message = split[this.index];
      this.index += 1 + (Math.random() > 0.4 ? 1 : 0);
      if (this.index > split.length - 1) this.index = 0;
    }
    if (window.ema * Math.random() > 0.08 ) setText(this.positiveText);
    if (window.ema * Math.random() < -0.08 ) setText(this.negativeText);

    const shapes = this.font.generateShapes( message, 100 );
    const geometry = new THREE.ShapeGeometry( shapes );
    geometry.computeBoundingBox();
    const xMid = - 0.5 * ( geometry.boundingBox.max.x - geometry.boundingBox.min.x );
    const yMid = - 0.5 * ( geometry.boundingBox.max.y - geometry.boundingBox.min.y );

    geometry.translate( xMid, yMid, 0 );
    this.object3D.geometry = geometry;
  }

  async getObject3D() {
    return this.object3D;
  }

  update() {
    let { rate, object3D, color, scale } = this;
    if (!object3D) return;

    this.ema = 0.8*(window.ema * 8 + ((window.karplusFollow?.output * 2) || 0) + ((window.kickFollow?.output) || 0) + ((window.pluckFollow?.output) || 0) + ((window.fmFollow?.output) || 0) + ((window.synthFollow?.output) || 0));
    this.triggerSpeed = ((window.busHigh?.output * 2) || 0) + ((window.busKick?.output) || 0) + ((window.window.busSecond?.output) || 0) + ((window.fmFollow?.output) || 0) + ((window.synthFollow?.output) || 0);
    this.trigger += this.triggerSpeed || 0;
    this.scaleFactor = lerp((this.triggerSpeed + window.uEma) * scale, 0, 0.4) * 9; 
    this.object3D.scale.set(this.scaleFactor, this.scaleFactor, this.scaleFactor)
    this.color = this.color.setHSL((window.ema > 0 ? this.scaleFactor : 0), 1, Math.random());
    if (this.trigger > rate * Math.random())
    {
      this.object3D.material.color = this.color;
    }
    if (this.trigger > rate)
    {
      this.object3D.material.opacity = Math.random();
      this.trigger = 0;
      this.changeGeometry();
    }
  }

}