import React, { Component } from 'react';
import threeEntryPoint from '../three/threeEntryPoint';

export default class ThreeContainer extends Component {
  constructor(props) {
    super(props);
    this.threeRootElement = React.createRef();
  }
  
  componentDidMount() {
    threeEntryPoint(this.threeRootElement.current);
  }

  render() {
    return <div id="threeContainer" ref={this.threeRootElement} />;
  }
}
