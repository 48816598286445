import {disconnectAll, connectAll} from './mftTools';

class AudioMachine {

  states = [];
  currentstates = [];

  constructor(scheduler) {
    this.scheduler = scheduler;
    window.debugStates = true;
  }

  AddState(state) {
    if (!this.currentstates.includes(state))
    {
      for(let s of this.currentstates)
      {
        // check if there is a state on the same track
        if (state.track === s.track)
        {
          
          if (state.priority > s.priority)
          {
            if (window.debugStates) console.warn(`[TRACK ${state.track}] 🔥🔥 prioritized ${state.name} ${state.priority} > ${s.name} ${s.priority} 🔥🔥`);
            this.RemoveState(s);
          }
          else
          {
            // lower prio, don't add
            return;
          }
        }
      }
      this.currentstates.push(state);
      if (window.debugStates) console.warn(`[STATE] 🤖 ${state.name} added ${(new Date()).getSeconds()}`);
      state.LoadState();
    }
  }

  RemoveState(state) {
    if (this.currentstates.includes(state))
    {
      if (window.debugStates) console.warn(`[STATE] 💀 ${state.name} removed ${(new Date()).getSeconds()}`);
      this.currentstates.splice(this.currentstates.indexOf(state),1);
      state.UnloadState(this);
    }
  }

  Update() {
    for(let i = this.currentstates.length - 1; i >= 0; i--)
    {
      let state = this.currentstates[i];
      let score = this.currentstates[i].CalculateScore();
      // check if current states have become invalid
      if (score < 0.5)
      {
        this.RemoveState(state);
      }
      else
      {
        state.OnExecute();
      }
    }
    // check if states meet the criteria to be added
    for(let i = 0; i < this.states.length; i++)
    {
      let score = this.states[i].CalculateScore();
      if (score >= 0.5)
      {
        this.AddState(this.states[i]);
      }
    }
  }

  State(params) {
    if (!params.name || !params.instruments || !params.conditions) console.error('State is missing parameters (name, instruments, conditions are required)');
    let state = new AudioState(params.name, params.instruments, params.conditions, params.priority || 0, params.track || 'default');
    Object.assign(state, params);
    this.states.push(state);
    if (window.debugStates) console.log(`${state.name} added`);
    return state;
  }

}

class AudioState {
  
  constructor(name, instruments, conditions, priority = 0, track = 'default') {
    this.name = name;
    this.instruments = instruments;
    this.conditions = conditions;
    this.priority = priority;
    this.track = track === 'default' ? 'default' + Math.random()*9999 : track;
  }

  // Calculate a score (between 0 and 1) based on the collider state
  CalculateScore(collider)
  {
    let score = 0;
    for(let c of this.conditions) {
      score += c();
    }
    return score;
  }

  LoadState() {
    connectAll(this.instruments);
    if (window.debugStates) console.log(this.instruments);
    this.OnEnter();
  }

  OnEnter() {

  }

  OnExecute() {

  }

  UnloadState(machine) {
    let instrumentsToRemove = this.instruments;
    // slice : only test first 2 elements of the array, not input loudness
    const equals = (a, b) => a.slice(0,2).every((v, i) => v === b[i]);
    for (let state of machine.currentstates) {
      let stateInstruments = state.instruments;
      for(let stateInst of stateInstruments)
      {
        instrumentsToRemove = instrumentsToRemove.filter( inst => !equals(inst, stateInst));
      }
    }
    let removals = ''
    for(let i of instrumentsToRemove)
    {
      removals += i[0].ugenName + (i[1] ? '+'+i[1].ugenName : '') + ', ';
    }
    if (window.debugStates && instrumentsToRemove.length > 0) console.log(`Removed ${removals}`);
    disconnectAll(instrumentsToRemove);
    this.OnExit();
  }

  OnExit() {

  }

}

function IsBetween(value, min, max) {
  return value > min && value < max ? 1 : 0;
}

export { AudioMachine, AudioState, IsBetween };