import { IsBetween } from '../audioMachine';
import { Collider } from '../mastercollider';

class MFTVIICollider extends Collider {
  Init() {
    const { Gibberish } = this.master;
    const {
      Shuffler,
      Sampler,
      BitCrusher,
      Delay,
      chord,
      Chorus,
      Complex,
      Monosynth,
      FM,
      Add,
      Sine,
      Saw,
      Snare,
      Distortion,
      Hat,
      PolyKarplus,
      Freeverb,
      Sequencer,
      Kick,
      Synth,
      Flanger,
      PolyFM,
      Follow,
      Scheduler,
      Vibrato,
      Bus,
      Bus2,
      Filter24TB303,
    } = Gibberish;
    const {
      Player,
      Tune,
      Theory,
      Music,
      Notes,
      beatscale,
      d3,
    } = this.master;
    const { ema, uEma, price } = window;

    let beat = 44100;

    Theory.tonicize(110);
    let tonic = [0, 3, 7, 13];
    let two = [2, 5, 8, 14];
    let three = [3, 6, 10, 15];
    let subdominant = [5, 8, 12, 17];
    let dominant = [7, 11, 14, 19];
    let six = [8, 12, 15, 20];

    let mainchord = tonic;
    Music.setChord(mainchord);
    Theory.loadScale('astro');
    Theory.loadScale('pyle');

    let ninCrushScale = d3
      .scalePow()
      .exponent(0.0075)
      .domain([0, 0.3])
      .range([0.001, 0.95]);

    let delfeedbackscale = d3
      .scalePow()
      .exponent(0.0075)
      .domain([0, 0.3])
      .range([0.001, 0.95]);

    let deltimescale = d3
      .scalePow()
      .exponent(0.025)
      .domain([0, 0.3])
      .range([beat*0.1, beat]);

    let deltimescale2 = d3
      .scalePow()
      .exponent(0.025)
      .domain([0, 0.3])
      .range([beat*0.1, beat]);

    let delfeedbackscale2 = d3
      .scalePow()
      .exponent(0.0075)
      .domain([0, 0.3])
      .range([0.001, 0.95]);

    let cutoffscale = d3
      .scalePow()
      .exponent(0.5)
      .domain([0, 0.3])
      .range([0.01, 0.95]);

    let cutoffscale2 = d3
      .scalePow()
      .exponent(0.025)
      .domain([0, 0.3])
      .range([0.999, 0.99]);

    let cutoffscale3 = d3
      .scalePow()
      .exponent(0.75)
      .domain([0, 0.3])
      .range([0.01, 0.95]);

    let cutoffscale4 = d3
      .scalePow()
      .exponent(0.75)
      .domain([0, 0.3])
      .range([0.01, 0.99]);

    let roomscale = d3
      .scalePow()
      .exponent(0.75)
      .domain([0, 0.3])
      .range([0.9, 0.985]);

      

      let voiceverb = Freeverb({
        input: Bus2(),
        roomSize: 0.995,
        damping: .4,
        inputGain: 1.5
      }).connect();

      

      let voicecrush = Shuffler({
        input: Bus2(),
        chance: .9,
        rate: 11050,
        repitchChance: .9,
        repitchMin:.75,
        repitchMax: 1.5,
        reverseChance: 0,
        mix: 1,
        inputGain: 1.6,
      }).connect(voiceverb.input);

      let preverb = Freeverb({
        input: Bus2(),
        roomSize: 0.95,
        damping: .95
      }).connect();

      let preverb2 = Freeverb({
        input: Bus2(),
        roomSize: 0.9,
        damping: .4
      }).connect();

    let predelay = Delay({
      input: Bus2(),
      delayTime: 5512,
      feedback: .15,
      inputGain: 1.5
    }).connect(preverb.input);

    let chorus = Chorus({
      input: Bus2(),
      slowGain: 2,
      slowFrequency: .03,
      fastFrequency: .99,
      fastGain: 4,
      inputGain: 1.5
    }).connect(preverb2.input);

    let verb = Freeverb({
      input: Bus2(),
      roomSize: 0.95,
      damping: .95
    }).connect();

    let bitcrush = BitCrusher({
      input: Bus2(),
      sampleRate: 0.15,
      inputGain: 1.9,
    }).connect(verb.input, 1);

    let filter = Filter24TB303({
      input: Bus2(),
      cutoff: 0.9,
      Q: 0.1,
    }).connect(bitcrush.input, 1);

    let bitcrushseq = Sequencer({
      target: bitcrush,
      key: 'sampleRate',
      values: [0.15, 0.9, 0.1, 0.8, 0.2, 0.99],
      timings: [beat * 0.25],
    }).start();

    let filtcutoffseq = Sequencer({
      target: filter,
      key: 'cutoff',
      values: [0.01, 0.9, 0.01, 0.8],
      timings: [beat * 0.25],
    }).start();

    let filtqseq = Sequencer({
      target: filter,
      key: 'q',
      values: [0.01, 0.9, 0.05, 0.8, 0.5, 0.25, 0.99],
      timings: [beat * 0.25],
    }).start();

    let bitcrush2 = BitCrusher({
      input: Bus2(),
      sampleRate: 0.15,
      inputGain: 2,
    }).connect(verb.input, 1);

    let filter2 = Filter24TB303({
      input: Bus2(),
      cutoff: 0.9,
      Q: 0.1,
    }).connect(bitcrush2.input, 1);

    let bitcrushseq2 = Sequencer({
      target: bitcrush2,
      key: 'sampleRate',
      values: [0.15, 0.99, 0.1, 0.8, 0.4, 0.99],
      timings: [beat * 0.5],
    }).start();

    let filtcutoffseq2 = Sequencer({
      target: filter2,
      key: 'cutoff',
      values: [0.01, 0.9, 0.01, 0.8],
      timings: [beat * 0.25],
    }).start();

    let filtqseq2 = Sequencer({
      target: filter2,
      key: 'q',
      values: [0.01, 0.9, 0.05, 0.8, 0.5, 0.25, 0.99],
      timings: [beat * 0.25],
    }).start();

    let delay = Delay({
      input: Bus2(),
      delayTime: beat,
      feedback: 0.95,
    }).connect(filter.input, 1);

    let delay2 = Delay({
      input: Bus2(),
      delayTime: beat*0.5,
      feedback: 0.95,
    }).connect(filter2.input, 1);

    let sampler = Sampler({
      filename:'./samples/voice1.wav',
      loops: false, 
      gain: .95
    });

    let ninCrush = BitCrusher({
      input: Bus2(),
      sampleRate: 0.15,
      bitCrusher: 0.6,
      inputGain: 0.6,
    }).connect();
    
    let ninBass = Monosynth({
      waveform: 'square',
      filterType: 1,
      filterMode: 0,
      cutoff: 0.99,
      Q: 0.25,
      filterMult: 2,
      gain: 0.4,
      useADSR: true,
      attack: beat * 0.01,
      decay: beat * 0.2,
      saturation: 5
    });

   

    let ninBassSeq = Sequencer({
      target: ninBass,
      key: 'note',
      values: [
        Theory.note(mainchord[0], -2),
        Theory.note(mainchord[1], -2),
        Theory.note(mainchord[0], -2),
      ],
      timings: [beat * 1],
    }).start();

    let ninBassGainSeq = Sequencer({
      target: ninBass,
      key: 'gain',
      values: [
        0.2,
        0.2,
        0.2
      ],
      timings: [beat * 1],
    }).start();


    

    let voiceseq = Sequencer({
      target: sampler,
      key: 'note',
      values: [
        1.2,
        1.4,
        1.2,
        1.4,
        1.2,
        1.8,
        1.2,
        2.4,
        1.8,
        1.4
      ],
      timings: [beat * 5],
    }).start();

    

    let voicegainseq = Sequencer({
      target: sampler, 
      key: 'gain',
      values: [ 
        0.0
      ],
      timings: [beat * 5]
    }).start;

    // sampler.onload = function() {
    //   sampler.note( 1 ) // play sample in reverse at 4x speed.
    // };

    
    


    let ethereal = Synth({
      waveform: 'saw',
      filterType: 1,
      filterMode: 0,
      cutoff: 0.5,
      Q: 0.1,
      filterMult: 1,
      gain: 0.15,
      decay: beat * 0.02,
      attack: beat * 2,
      saturation: 1,
    });

    let etherealseq = Sequencer({
      target: ethereal,
      key: 'note',
      values: [
        Theory.note(mainchord[0], 2),
        Theory.note(mainchord[1], 1),
        Theory.note(mainchord[0], 3),
        Theory.note(mainchord[2], 1),
        Theory.note(mainchord[0], 2),
        Theory.note(mainchord[1], 1),
        Theory.note(mainchord[0], 3),
        Theory.note(mainchord[2], 1),
        Theory.note(mainchord[0], 2),
        Theory.note(mainchord[1], 1),
        Theory.note(mainchord[0], 3),
        Theory.note(mainchord[2], 1),
        Theory.note(mainchord[0], 4),
        Theory.note(mainchord[1], 2),
        Theory.note(mainchord[0], 6),
        Theory.note(mainchord[2], 2),
      ],
      timings: [beat * 1],
    }).start();

    
    

    let etherealgainseq = Sequencer({
      target: ethereal,
      key: 'gain',
      values: [0.25, 0, 0.2, 0, 0, 0.3],
      timings: [beat * 1],
    }).start();

    let ethereal2 = Synth({
      waveform: 'saw',
      filterType: 3,
      filterMode: 0,
      cutoff: 0.05,
      Q: 0.1,
      filterMult: 1,
      gain: 0.15,
      decay: beat * 1,
      attack: beat * 2,
      saturation: 1,
    });

    window.busHigh = Follow({input:ethereal2});
    window.busSecond = Follow({input:ethereal});

    let etherealseq2 = Sequencer({
      target: ethereal2,
      key: 'note',
      values: [
        Theory.note(mainchord[0], 0),
        Theory.note(mainchord[1], 0),
        Theory.note(mainchord[0], 0),
        Theory.note(mainchord[2], 0),
      ],
      timings: [beat * 1],
    }).start();

    
    let etherealgainseq2 = Sequencer({
      target: ethereal2,
      key: 'gain',
      values: [0.45, 0, 0, 0.2, 0, 0.3, 0, 0],
      timings: [beat * 1],
    }).start();

    let ethereal3 = Synth({
      waveform: 'sine',
      filterType: 4,
      filterMode: 1,
      cutoff: 0.8,
      Q: 0.4,
      filterMult: 1,
      gain: 0.15,
      decay: beat * 0.02,
      attack: beat * 2,
      saturation: 80,
    });

    let etherealseq3 = Sequencer({
      target: ethereal3,
      key: 'note',
      values: [
        Theory.note(mainchord[0], 0),
        Theory.note(mainchord[2], 1),
        Theory.note(mainchord[1], 1),
        Theory.note(mainchord[2], -1),
        Theory.note(mainchord[0], 0),
        Theory.note(mainchord[1], 1),
        Theory.note(mainchord[0], 1),
        Theory.note(mainchord[2], 0),
        Theory.note(mainchord[2], 1),
        Theory.note(mainchord[1], 1),
        Theory.note(mainchord[0], 3),
        Theory.note(mainchord[2], 1),
        Theory.note(mainchord[0], 0),
        Theory.note(mainchord[1], 2),
        Theory.note(mainchord[1], 1),
        Theory.note(mainchord[2], 2),
      ],
      timings: [beat * 1],
    }).start();

 
    

    let etherealgainseq3 = Sequencer({
      target: ethereal3,
      key: 'gain',
      values: [0.25, 0.1, 0.0, 0.0, 0.3, 0.0],
      timings: [beat * 1],
    }).start();

    let ethereal4 = Synth({
      waveform: 'pwm',
      filterType: 2,
      filterMode: 0,
      cutoff: 0.1,
      Q: 0.3,
      filterMult: 50,
      gain: 0.1,
      decay: beat * 0.001,
      attack: beat * 0.2,
      saturation: 98,
    });
    

    let etherealseq4 = Sequencer({
      target: ethereal4,
      key: 'note',
      values: [
        Theory.note(mainchord[2], -1),
        Theory.note(mainchord[0], -2),
        Theory.note(mainchord[0], -1),
        Theory.note(mainchord[2], -2),
        Theory.note(mainchord[0], 0),
        Theory.note(mainchord[1], 0),
        Theory.note(mainchord[2], -3),
        Theory.note(mainchord[2], -1),
      ],
      timings: [beat * 1],
    }).start();

    let etherealgainseq4 = Sequencer({
      target: ethereal4,
      key: 'gain',
      values: [0.05, 0.125, 0.1, 0.0, 0.0, 0.05, 0.12, 0.17, 0.0, 0.15],
      timings: [beat * 1],
    }).start();

    
    let etherealcutoffseq4 = Sequencer({
      target: ethereal4,
      key: 'cutoff',
      values: [0.99, 0.01, 0.8, 0.03, 0.25, 0.9, 0.5, 0.7, 0.35, 0.8],
      timings: [beat * 0.125],
    }).start();

    

    Player.State({
      name: 'Ultra Fast and Positive',
      instruments: [
        [sampler, voiceverb.input],
        [ethereal, predelay.input],
        [ethereal],
        [ethereal2,chorus.input],
        [ethereal3, predelay.input],
        [ethereal3],
        [ethereal4, chorus.input],
      ],
      conditions: [() => IsBetween(window.ema, 0.075, 999)],
      OnEnter: function () {

        console.log('test positive');
        Theory.loadScale('pyle');
			  mainchord = dominant; 

        voiceverb.roomSize = 0.995;
        voiceseq.values = [1.55, 1.75, 1.55, 1.9, 1.55, 1.9, 3.1, 2.25, 1.75];
        voicegainseq.values = [ 0.95,1.1,1.4,0,0,0.99,0.0,1.2,0.8,0.0,0.0,1.1,1.2,1.3,0.0,0.0,0.0,0.95,1.0,1.1,0.8,0.9,1.1 ];
  

        ethereal.attack = beat * 0.001;
        ethereal2.attack = beat * 0.05;
        ethereal3.attack = beat * 0.001;
        ethereal4.attack = beat * 0.001;

        ethereal.decay = beat * 0.5;
        ethereal2.decay = beat * 0.15;
        ethereal3.decay = beat * 0.5;
        ethereal4.decay = beat * 0.1;

        ethereal.filterMult = 6;
        ethereal2.filterMult = 1;
        ethereal3.filterMult = 4;
        ethereal4.filterMult = 3;

        etherealgainseq2.values = [0.25,0.25,0.25,0.25,0.25,0,0,0,0,0.2,0.4,0.2,0.2,0.4,0.2,0,0,0,0.3,0.2,0.3,0.6,0.3,0.6,0,0,0,0,0.4,0,0.3,0.3,0.4,0.2,0.2,0,0,0,0,0,0];
        etherealgainseq3.values = [0.15]; 
        etherealgainseq4.values = [0.4, 0.5, 0.4, 0.0, 0.0, 0.1, 0.4, 0.4, 0.0, 0.5];

        cutoffscale = d3
        .scalePow()
        .exponent(0.025)
        .domain([0.075, 999])
        .range([0.05, 0.95]);

        cutoffscale3 = d3
        .scalePow()
        .exponent(0.025)
        .domain([0.075, 999])
        .range([0.01, 0.95]);
      
        cutoffscale3 = d3
        .scalePow()
        .exponent(0.025)
        .domain([0.075, 999])
        .range([0.01, 0.95]);
        
        etherealcutoffseq4.values = [0.99, 0.01, 0.8, 0.03, 0.25, 0.9, 0.5, 0.7, 0.35, 0.8];

        etherealseq.values = [  
          Theory.note(mainchord[0], 2),
          Theory.note(mainchord[1], 1),
          Theory.note(mainchord[0], 3),
          Theory.note(mainchord[2], 1),
          Theory.note(mainchord[0], 2),
          Theory.note(mainchord[1], 1),
          Theory.note(mainchord[0], 3),
          Theory.note(mainchord[2], 1),
          Theory.note(mainchord[0], 2),
          Theory.note(mainchord[1], 1),
          Theory.note(mainchord[0], 3),
          Theory.note(mainchord[2], 1),
          Theory.note(mainchord[0], 4),
          Theory.note(mainchord[1], 2),
          Theory.note(mainchord[0], 6),
          Theory.note(mainchord[2], 2) ];

          etherealseq2.values = [
            Theory.note(mainchord[0], -1),
            Theory.note(mainchord[0], 0),
            Theory.note(mainchord[0], 1),
            Theory.note(mainchord[1], -1),
            Theory.note(mainchord[1], 0),
            Theory.note(mainchord[1], 1),
            Theory.note(mainchord[2], -1),
            Theory.note(mainchord[2], 0),
            Theory.note(mainchord[2], 1)

          ];
          

          etherealseq3.values = [
            Theory.note(mainchord[0], 0),
            Theory.note(mainchord[2], 1),
            Theory.note(mainchord[1], 1),
            Theory.note(mainchord[2], -1),
            Theory.note(mainchord[0], 0),
            Theory.note(mainchord[1], 1),
            Theory.note(mainchord[0], 1),
            Theory.note(mainchord[2], 0),
            Theory.note(mainchord[2], 1),
            Theory.note(mainchord[1], 1),
            Theory.note(mainchord[0], 3),
            Theory.note(mainchord[2], 1),
            Theory.note(mainchord[0], 0),
            Theory.note(mainchord[1], 2),
            Theory.note(mainchord[1], 1),
            Theory.note(mainchord[2], 2),
          ];

          etherealseq4.values = [
            Theory.note(mainchord[2], -1),
            Theory.note(mainchord[0], -2),
            Theory.note(mainchord[0], -1),
            Theory.note(mainchord[2], -2),
            Theory.note(mainchord[0], -2),
            Theory.note(mainchord[1], -2),
            Theory.note(mainchord[2], -3),
            Theory.note(mainchord[2], -1),
            Theory.note(mainchord[0], -2),
            Theory.note(mainchord[0], -3),
            Theory.note(mainchord[0], -3),
            Theory.note(mainchord[0], -3),
        ];


      },
      OnExecute: () => {
        let { beat, uEma } = window;

        delay.delayTime = deltimescale(uEma);
        delay.feedback = delfeedbackscale(uEma);
        delay2.delayTime = deltimescale2(uEma);
        delay2.feedback = delfeedbackscale2(uEma);

        voiceseq.timings = [ beat * 5 ];
        voicegainseq.timings = [ beat * 5 ];
       
  
        filtcutoffseq.timings = [beat * 0.08];
        filtqseq.timings = [beat * 0.025];
        bitcrushseq.timings = [beat * 0.25];
  
        bitcrushseq2.timings = [beat * 0.5];
        filtcutoffseq2.timings = [beat * 0.25];
        filtqseq2.timings = [beat * 0.25];
  
        ethereal.cutoff = cutoffscale(uEma);
        ethereal2.cutoff = cutoffscale2(uEma);
        ethereal3.cutoff = cutoffscale3(uEma);
  
        verb.roomSize = roomscale(uEma);
        //ethereal4.cutoff = cutoffscale4(uEma);
  
        etherealseq.timings = [beat * 0.5];
        //etherealgainseq.timings = [beat * 0.5];
        etherealgainseq.timings = [beat * 0.5];
  
        etherealseq2.timings = [beat * 0.125];
        //etherealgainseq2.timings = [beat * 0.25];
        etherealgainseq2.timings = [beat * 0.125];
  
        etherealseq3.timings = [beat * 0.5];
        //etherealgainseq3.timings = [beat * 0.49];
        etherealgainseq3.timings = [beat * 0.5];
  
        etherealseq4.timings = [beat * 0.125];
        //etherealgainseq4.timings = [beat * 0.24];
        etherealgainseq4.timings = [beat * 0.125];
        etherealcutoffseq4.timing = [beat * 0.125];
      },
    });

    Player.State({
      name: 'Super Fast and Positive',
      instruments: [
        [sampler, voiceverb.input],
        [ethereal, chorus.input],
        [ethereal2, chorus.input],
        [ethereal3, predelay.input],
        [ethereal4, predelay.input],
      ],
      conditions: [() => IsBetween(window.ema, 0.065625, 0.075)],
      OnEnter: function () {

        console.log('test positive');
        Theory.loadScale('pyle');
			  mainchord = subdominant; 

        cutoffscale = d3
        .scalePow()
        .exponent(0.025)
        .domain([0.065625, 0.075])
        .range([0.05, 0.75]);

        cutoffscale3 = d3
        .scalePow()
        .exponent(0.025)
        .domain([0.065625, 0.075])
        .range([0.05, 0.75]);
      
        cutoffscale3 = d3
        .scalePow()
        .exponent(0.025)
        .domain([0.065625, 0.075])
        .range([0.05, 0.75]);

        voiceverb.roomSize = 0.995;
        voiceseq.values = [1.45, 1.6, 1.45, 1.6, 1.45, 2, 1.45, 2.4, 2, 1.6];
    

        ethereal.attack = beat * 0.001;
        ethereal2.attack = beat * 0.05;
        ethereal3.attack = beat * 0.001;
        ethereal4.attack = beat * 0.001;

        ethereal.decay = beat * 0.5;
        ethereal2.decay = beat * 0.45;
        ethereal3.decay = beat * 0.25;
        ethereal4.decay = beat * 0.3;

        ethereal.filterMult = 4;
        ethereal2.filterMult = 1;
        ethereal3.filterMult = 5;
        ethereal4.filterMult = 4;


        
        
        
        etherealcutoffseq4.values = [0.99, 0.01, 0.8, 0.03, 0.25, 0.9, 0.5, 0.7, 0.35, 0.8];


        etherealseq.values = [  
          Theory.note(mainchord[0], 2),
          Theory.note(mainchord[1], 1),
          Theory.note(mainchord[0], 3),
          Theory.note(mainchord[2], 1),
          Theory.note(mainchord[0], 2),
          Theory.note(mainchord[1], 1),
          Theory.note(mainchord[0], 3),
          Theory.note(mainchord[2], 1),
          Theory.note(mainchord[0], 2),
          Theory.note(mainchord[1], 1),
          Theory.note(mainchord[0], 3),
          Theory.note(mainchord[2], 1),
          Theory.note(mainchord[0], 4),
          Theory.note(mainchord[1], 2),
          Theory.note(mainchord[0], 6),
          Theory.note(mainchord[2], 2) ];

          etherealseq2.values = [
            Theory.note(mainchord[0], 0),
            Theory.note(mainchord[1], 0),
            Theory.note(mainchord[0], 0),
            Theory.note(mainchord[2], 0),
          ];

          etherealseq3.values = [
            Theory.note(mainchord[0], 0),
            Theory.note(mainchord[2], 1),
            Theory.note(mainchord[1], 1),
            Theory.note(mainchord[2], -1),
            Theory.note(mainchord[0], 0),
            Theory.note(mainchord[1], 1),
            Theory.note(mainchord[0], 1),
            Theory.note(mainchord[2], 0),
            Theory.note(mainchord[2], 1),
            Theory.note(mainchord[1], 1),
            Theory.note(mainchord[0], 3),
            Theory.note(mainchord[2], 1),
            Theory.note(mainchord[0], 0),
            Theory.note(mainchord[1], 2),
            Theory.note(mainchord[1], 1),
            Theory.note(mainchord[2], 2),
          ];

          etherealseq4.values = [
            Theory.note(mainchord[2], -1),
            Theory.note(mainchord[0], -2),
            Theory.note(mainchord[0], -1),
            Theory.note(mainchord[2], -2),
            Theory.note(mainchord[0], 0),
            Theory.note(mainchord[1], 0),
            Theory.note(mainchord[2], -3),
            Theory.note(mainchord[2], -1),
        ];


      },
      OnExecute: () => {
        let { beat, uEma } = window;

        delay.delayTime = deltimescale(uEma);
        delay.feedback = delfeedbackscale(uEma);
        delay2.delayTime = deltimescale2(uEma);
        delay2.feedback = delfeedbackscale2(uEma);

        voiceseq.timings = [ beat * 5 ];
        voicegainseq.timings = [ beat * 5 ];
        voicegainseq.values = [ 0.85,0, 0.7,0,0,0.8,0.0,0.7 ];
  
        filtcutoffseq.timings = [beat * 0.08];
        filtqseq.timings = [beat * 0.025];
        bitcrushseq.timings = [beat * 0.25];
  
        bitcrushseq2.timings = [beat * 0.5];
        filtcutoffseq2.timings = [beat * 0.25];
        filtqseq2.timings = [beat * 0.25];
  
        ethereal.cutoff = cutoffscale(uEma);
        ethereal2.cutoff = cutoffscale2(uEma);
        ethereal3.cutoff = cutoffscale3(uEma);
  
        verb.roomSize = roomscale(uEma);
        //ethereal4.cutoff = cutoffscale4(uEma);
  
        etherealseq.timings = [beat * 0.25];
        //etherealgainseq.timings = [beat * 0.5];
        etherealgainseq.timings = [beat * 0.25];
  
        etherealseq2.timings = [beat * 0.125];
        //etherealgainseq2.timings = [beat * 0.25];
        etherealgainseq2.timings = [beat * 0.125];
  
        etherealseq3.timings = [beat * 0.25];
        //etherealgainseq3.timings = [beat * 0.49];
        etherealgainseq3.timings = [beat * 0.25];
  
        etherealseq4.timings = [beat * 0.125];
        //etherealgainseq4.timings = [beat * 0.24];
        etherealgainseq4.timings = [beat * 0.125];
        etherealcutoffseq4.timing = [beat * 0.125];
      },
    });

    Player.State({
      name: 'Very Fast and Positive',
      instruments: [
        [sampler, voiceverb.input],
        [ethereal, chorus.input],
        [ethereal2, chorus.input],
        [ethereal3, predelay.input],
        [ethereal4, predelay.input],
      ],
      conditions: [() => IsBetween(window.ema, 0.05625, 0.065625)],
      OnEnter: function () {

        console.log('test positive');
        Theory.loadScale('pyle');
			  mainchord = three; 

        voiceverb.roomSize = 0.995;
        voiceseq.values = [1.2, 1.4, 1.2, 1.4, 1.2, 1.8, 1.2, 2.4, 1.8, 1.4];
    

        cutoffscale = d3
          .scalePow()
          .exponent(0.025)
          .domain([0.05625, 0.065625])
          .range([0.05, 0.75]);

        cutoffscale3 = d3
          .scalePow()
          .exponent(0.025)
          .domain([0.05625, 0.065625])
          .range([0.05, 0.35]);
        
        cutoffscale3 = d3
          .scalePow()
          .exponent(0.025)
          .domain([0.05625, 0.065625])
          .range([0.05, 0.35]);

          ethereal.attack = beat * 0.01;
          ethereal2.attack = beat * 0.05;
          ethereal3.attack = beat * 0.01;
          ethereal4.attack = beat * 0.01;
  
          ethereal.decay = beat;
          ethereal2.decay = beat;
          ethereal3.decay = beat * 0.75;
          ethereal4.decay = beat * 0.3;
  
          ethereal.filterMult = 5;
          ethereal2.filterMult = 1;
          ethereal3.filterMult = 3;
          ethereal4.filterMult = 4;
        
        etherealcutoffseq4.values = [0.99, 0.01, 0.8, 0.03, 0.25, 0.9, 0.5, 0.7, 0.35, 0.8];


        etherealseq.values = [  
          Theory.note(mainchord[0], 2),
          Theory.note(mainchord[1], 1),
          Theory.note(mainchord[0], 3),
          Theory.note(mainchord[2], 1),
          Theory.note(mainchord[0], 2),
          Theory.note(mainchord[1], 1),
          Theory.note(mainchord[0], 3),
          Theory.note(mainchord[2], 1),
          Theory.note(mainchord[0], 2),
          Theory.note(mainchord[1], 1),
          Theory.note(mainchord[0], 3),
          Theory.note(mainchord[2], 1),
          Theory.note(mainchord[0], 4),
          Theory.note(mainchord[1], 2),
          Theory.note(mainchord[0], 6),
          Theory.note(mainchord[2], 2) ];

          etherealseq2.values = [
            Theory.note(mainchord[0], 0),
            Theory.note(mainchord[1], 0),
            Theory.note(mainchord[0], 0),
            Theory.note(mainchord[2], 0),
          ];

          etherealseq3.values = [
            Theory.note(mainchord[0], 0),
            Theory.note(mainchord[2], 1),
            Theory.note(mainchord[1], 1),
            Theory.note(mainchord[2], -1),
            Theory.note(mainchord[0], 0),
            Theory.note(mainchord[1], 1),
            Theory.note(mainchord[0], 1),
            Theory.note(mainchord[2], 0),
            Theory.note(mainchord[2], 1),
            Theory.note(mainchord[1], 1),
            Theory.note(mainchord[0], 3),
            Theory.note(mainchord[2], 1),
            Theory.note(mainchord[0], 0),
            Theory.note(mainchord[1], 2),
            Theory.note(mainchord[1], 1),
            Theory.note(mainchord[2], 2),
          ];

          etherealseq4.values = [
            Theory.note(mainchord[2], -1),
            Theory.note(mainchord[0], -2),
            Theory.note(mainchord[0], -1),
            Theory.note(mainchord[2], -2),
            Theory.note(mainchord[0], 0),
            Theory.note(mainchord[1], 0),
            Theory.note(mainchord[2], -3),
            Theory.note(mainchord[2], -1),
        ];


      },
      OnExecute: () => {
        let { beat, uEma } = window;

        delay.delayTime = deltimescale(uEma);
        delay.feedback = delfeedbackscale(uEma);
        delay2.delayTime = deltimescale2(uEma);
        delay2.feedback = delfeedbackscale2(uEma);

        voiceseq.timings = [ beat * 5 ];
        voicegainseq.timings = [ beat * 5 ];
        voicegainseq.values = [ 0.75,0, 0.6,0,0,0.5,0.0,0.0,0.0,0.6 ];
  
        filtcutoffseq.timings = [beat * 0.08];
        filtqseq.timings = [beat * 0.025];
        bitcrushseq.timings = [beat * 0.25];
  
        bitcrushseq2.timings = [beat * 0.5];
        filtcutoffseq2.timings = [beat * 0.25];
        filtqseq2.timings = [beat * 0.25];

        ethereal.attack = beat * 0.01;
        ethereal2.attack = beat * 0.05;
        ethereal3.attack = beat * 0.01;
        ethereal4.attack = beat * 0.01;
  
        ethereal.decay = beat * 2;
        ethereal2.decay = beat * 2;
        ethereal3.decay = beat * 1;
        ethereal4.decay = beat * 0.75;
  
        ethereal.filterMult = 4;
        ethereal2.filterMult = 1;
        ethereal3.filterMult = 3;
        ethereal4.filterMult = 3;
  
        ethereal.cutoff = cutoffscale(uEma);
        ethereal2.cutoff = cutoffscale2(uEma);
        ethereal3.cutoff = cutoffscale3(uEma);
  
        verb.roomSize = roomscale(uEma);
        //ethereal4.cutoff = cutoffscale4(uEma);
  
        etherealseq.timings = [beat * 0.25];
        //etherealgainseq.timings = [beat * 0.5];
        etherealgainseq.timings = [beat * 0.25];
  
        etherealseq2.timings = [beat * 0.125];
        //etherealgainseq2.timings = [beat * 0.25];
        etherealgainseq2.timings = [beat * 0.125];
  
        etherealseq3.timings = [beat * 0.25];
        //etherealgainseq3.timings = [beat * 0.49];
        etherealgainseq3.timings = [beat * 0.25];
  
        etherealseq4.timings = [beat * 0.125];
        //etherealgainseq4.timings = [beat * 0.24];
        etherealgainseq4.timings = [beat * 0.125];
        etherealcutoffseq4.timing = [beat * 0.125];
      },
    });

    Player.State({
      name: 'Fast and Positive',
      instruments: [
        [ethereal, chorus.input],
        [ethereal2, chorus.input],
        [ethereal3, predelay.input],
        [ethereal4, predelay.input],
      ],
      conditions: [() => IsBetween(window.ema, 0.046875, 0.05625)],
      OnEnter: function () {

        console.log('test positive');
        Theory.loadScale('pyle');
			  mainchord = dominant; 

        cutoffscale = d3
          .scalePow()
          .exponent(0.025)
          .domain([0.046875, 0.05625])
          .range([0.05, 0.35]);

        cutoffscale3 = d3
          .scalePow()
          .exponent(0.025)
          .domain([0.046875, 0.05625])
          .range([0.05, 0.35]);
        
        cutoffscale3 = d3
          .scalePow()
          .exponent(0.025)
          .domain([0.046875, 0.05625])
          .range([0.05, 0.75]);

        ethereal.attack = beat * 0.025;
        ethereal2.attack = beat * 0.06;
        ethereal3.attack = beat * 0.04;
        ethereal4.attack = beat * 0.03;
  
        ethereal.decay = beat * 3;
        ethereal2.decay = beat * 3;
        ethereal3.decay = beat * 1.5;
        ethereal4.decay = beat * 0.75;
  
        ethereal.filterMult = 3;
        ethereal2.filterMult = 1;
        ethereal3.filterMult = 3;
        ethereal4.filterMult = 2;

        etherealgainseq.values = [0.25, 0, 0.2, 0, 0, 0.3];
        etherealgainseq2.values = [0.45, 0, 0, 0.2, 0, 0.3, 0, 0];
        etherealgainseq3.values = [0.25, 0.1, 0.0, 0.0, 0.3, 0.0];
        etherealgainseq4.values = [0.05, 0.125, 0.1, 0.0, 0.0, 0.05, 0.12, 0.17, 0.0, 0.15];
        etherealcutoffseq4.values = [0.99, 0.01, 0.8, 0.03, 0.25, 0.9, 0.5, 0.7, 0.35, 0.8];

        etherealseq.values = [  
          Theory.note(mainchord[0], 2),
          Theory.note(mainchord[1], 1),
          Theory.note(mainchord[0], 3),
          Theory.note(mainchord[2], 1),
          Theory.note(mainchord[0], 2),
          Theory.note(mainchord[1], 1),
          Theory.note(mainchord[0], 3),
          Theory.note(mainchord[2], 1),
          Theory.note(mainchord[0], 2),
          Theory.note(mainchord[1], 1),
          Theory.note(mainchord[0], 3),
          Theory.note(mainchord[2], 1),
          Theory.note(mainchord[0], 4),
          Theory.note(mainchord[1], 2),
          Theory.note(mainchord[0], 6),
          Theory.note(mainchord[2], 2) ];

          etherealseq2.values = [
            Theory.note(mainchord[0], 0),
            Theory.note(mainchord[1], 0),
            Theory.note(mainchord[0], 0),
            Theory.note(mainchord[2], 0),
          ];

          etherealseq3.values = [
            Theory.note(mainchord[0], 0),
            Theory.note(mainchord[2], 1),
            Theory.note(mainchord[1], 1),
            Theory.note(mainchord[2], -1),
            Theory.note(mainchord[0], 0),
            Theory.note(mainchord[1], 1),
            Theory.note(mainchord[0], 1),
            Theory.note(mainchord[2], 0),
            Theory.note(mainchord[2], 1),
            Theory.note(mainchord[1], 1),
            Theory.note(mainchord[0], 3),
            Theory.note(mainchord[2], 1),
            Theory.note(mainchord[0], 0),
            Theory.note(mainchord[1], 2),
            Theory.note(mainchord[1], 1),
            Theory.note(mainchord[2], 2),
          ];

          etherealseq4.values = [
            Theory.note(mainchord[2], -1),
            Theory.note(mainchord[0], -2),
            Theory.note(mainchord[0], -1),
            Theory.note(mainchord[2], -2),
            Theory.note(mainchord[0], 0),
            Theory.note(mainchord[1], 0),
            Theory.note(mainchord[2], -3),
            Theory.note(mainchord[2], -1),
        ];


      },
      OnExecute: () => {
        let { beat, uEma } = window;

        delay.delayTime = deltimescale(uEma);
        delay.feedback = delfeedbackscale(uEma);
        delay2.delayTime = deltimescale2(uEma);
        delay2.feedback = delfeedbackscale2(uEma);
  
        filtcutoffseq.timings = [beat * 0.08];
        filtqseq.timings = [beat * 0.025];
        bitcrushseq.timings = [beat * 0.25];
  
        bitcrushseq2.timings = [beat * 0.5];
        filtcutoffseq2.timings = [beat * 0.25];
        filtqseq2.timings = [beat * 0.25];

        ethereal.attack = beat * 0.05;
        ethereal2.attack = beat * 0.07;
        ethereal3.attack = beat * 0.05;
        ethereal4.attack = beat * 0.04;
  
        ethereal.decay = beat * 4;
        ethereal2.decay = beat * 4;
        ethereal3.decay = beat * 2.5;
        ethereal4.decay = beat * 1.0;
  
        ethereal.filterMult = 1;
        ethereal2.filterMult = 1;
        ethereal3.filterMult = 2;
        ethereal4.filterMult = 3;
  
        ethereal.cutoff = cutoffscale(uEma);
        ethereal2.cutoff = cutoffscale2(uEma);
        ethereal3.cutoff = cutoffscale3(uEma);
  
        verb.roomSize = roomscale(uEma);
        //ethereal4.cutoff = cutoffscale4(uEma);
  
        etherealseq.timings = [beat * 0.125];
        //etherealgainseq.timings = [beat * 0.5];
        etherealgainseq.timings = [beat * 0.125];
  
        etherealseq2.timings = [beat * 0.25];
        //etherealgainseq2.timings = [beat * 0.25];
        etherealgainseq2.timings = [beat * 0.25];
  
        etherealseq3.timings = [beat * 0.5];
        //etherealgainseq3.timings = [beat * 0.49];
        etherealgainseq3.timings = [beat * 0.5];
  
        etherealseq4.timings = [beat * 0.125];
        //etherealgainseq4.timings = [beat * 0.24];
        etherealgainseq4.timings = [beat * 0.125];
        etherealcutoffseq4.timing = [beat * 0.125];
      },
    });

    Player.State({
      name: 'Moderate and Positive',
      instruments: [
        [ethereal, chorus.input],
        [ethereal2, chorus.input],
        [ethereal3, predelay.input],
        [ethereal4, predelay.input],
      ],
      conditions: [() => IsBetween(window.ema, 0.0375, 0.046875)],
      OnEnter: function () {

        console.log('test positive');
        Theory.loadScale('pyle');
			  mainchord = tonic; 

        cutoffscale = d3
          .scalePow()
          .exponent(0.025)
          .domain([0.0375, 0.046875])
          .range([0.15, 0.5]);

        cutoffscale2 = d3
          .scalePow()
          .exponent(0.025)
          .domain([0.0375, 0.046875])
          .range([0.05, 0.25]);

        cutoffscale3 = d3
          .scalePow()
          .exponent(0.025)
          .domain([0.0375, 0.046875])
          .range([0.25, 0.35]);
        
        cutoffscale3 = d3
          .scalePow()
          .exponent(0.025)
          .domain([0.0375, 0.046875])
          .range([0.35, 0.5]);

        ethereal.attack = beat * 0.05;
        ethereal2.attack = beat * 0.07;
        ethereal3.attack = beat * 0.001;
        ethereal4.attack = beat * 0.001;
  
        ethereal.decay = beat * 6;
        ethereal2.decay = beat * 0.2;
        ethereal3.decay = beat * 0.2;
        ethereal4.decay = beat * 0.25;
  
        ethereal.filterMult = 1;
        ethereal2.filterMult = 1;
        ethereal3.filterMult = 1;
        ethereal4.filterMult = 2;

        etherealgainseq.values = [0.25, 0, 0.2, 0, 0, 0.3];
        etherealgainseq2.values = [0.8, 0, 0, 0.6, 0, 0.65, 0, 0];
        etherealgainseq3.values = [0.25, 0.4, 0.0, 0.0, 0.3, 0.0];
        //etherealgainseq4.values = [0.9, 0.75, 0.8, 0.0, 0.0, 0.8, 0.8, 0.9, 0.0, 0.9];
        etherealgainseq4.values = [2];
        etherealcutoffseq4.values = [0.45, 0.01, 0.4, 0.03, 0.5, 0.3, 0.25, 0.3, 0.15, 0.4];

        etherealseq.values = [  
          Theory.note(mainchord[0], 2),
          Theory.note(mainchord[1], 1),
          Theory.note(mainchord[0], 3),
          Theory.note(mainchord[2], 1),
          Theory.note(mainchord[0], 2),
          Theory.note(mainchord[1], 1),
          Theory.note(mainchord[0], 3),
          Theory.note(mainchord[2], 1),
          Theory.note(mainchord[0], 2),
          Theory.note(mainchord[1], 1),
          Theory.note(mainchord[0], 3),
          Theory.note(mainchord[2], 1),
          Theory.note(mainchord[0], 4),
          Theory.note(mainchord[1], 2),
          Theory.note(mainchord[0], 6),
          Theory.note(mainchord[2], 2) ];

          etherealseq2.values = [
            Theory.note(mainchord[0], 0),
            Theory.note(mainchord[1], 0),
            Theory.note(mainchord[0], 0),
            Theory.note(mainchord[2], 0),
          ];

          etherealseq3.values = [
            Theory.note(mainchord[0], 0),
            Theory.note(mainchord[2], 1),
            Theory.note(mainchord[1], 1),
            Theory.note(mainchord[2], -1),
            Theory.note(mainchord[0], 0),
            Theory.note(mainchord[1], 1),
            Theory.note(mainchord[0], 1),
            Theory.note(mainchord[2], 0),
            Theory.note(mainchord[2], 1),
            Theory.note(mainchord[1], 1),
            Theory.note(mainchord[0], 3),
            Theory.note(mainchord[2], 1),
            Theory.note(mainchord[0], 0),
            Theory.note(mainchord[1], 2),
            Theory.note(mainchord[1], 1),
            Theory.note(mainchord[2], 2),
          ];

          etherealseq4.values = [
            Theory.note(mainchord[2], -1),
            Theory.note(mainchord[0], -2),
            Theory.note(mainchord[0], -1),
            Theory.note(mainchord[2], -2),
            Theory.note(mainchord[0], 0),
            Theory.note(mainchord[1], 0),
            Theory.note(mainchord[2], -3),
            Theory.note(mainchord[2], -1),
        ];


      },
      OnExecute: () => {
        let { beat, uEma } = window;

        delay.delayTime = deltimescale(uEma);
        delay.feedback = delfeedbackscale(uEma);
        delay2.delayTime = deltimescale2(uEma);
        delay2.feedback = delfeedbackscale2(uEma);
  
        filtcutoffseq.timings = [beat * 0.08];
        filtqseq.timings = [beat * 0.025];
        bitcrushseq.timings = [beat * 0.25];
  
        bitcrushseq2.timings = [beat * 0.5];
        filtcutoffseq2.timings = [beat * 0.25];
        filtqseq2.timings = [beat * 0.25];
  
        ethereal.cutoff = cutoffscale(uEma);
        ethereal2.cutoff = cutoffscale2(uEma);
        ethereal3.cutoff = cutoffscale3(uEma);
  
        verb.roomSize = roomscale(uEma);
        //ethereal4.cutoff = cutoffscale4(uEma);
  
        etherealseq.timings = [beat * 0.25];
        //etherealgainseq.timings = [beat * 0.5];
        etherealgainseq.timings = [beat * 0.25];
  
        etherealseq2.timings = [beat * 0.5];
        //etherealgainseq2.timings = [beat * 0.25];
        etherealgainseq2.timings = [beat * 0.5];
  
        etherealseq3.timings = [beat * 0.25];
        //etherealgainseq3.timings = [beat * 0.49];
        etherealgainseq3.timings = [beat * 0.25];
  
        etherealseq4.timings = [beat * 0.25];
        //etherealgainseq4.timings = [beat * 0.24];
        etherealgainseq4.timings = [beat * 0.25];
        etherealcutoffseq4.timing = [beat * 0.25];
      },
    });

    Player.State({
      name: 'Slow and Positive',
      instruments: [
        [ethereal, chorus.input],
        [ethereal2, chorus.input],
        [ethereal3, predelay.input],
        [ethereal4, predelay.input],
      ],
      conditions: [() => IsBetween(window.ema, 0.028125, 0.0375)],
      OnEnter: function () {

        console.log('test positive');
        Theory.loadScale('pyle');
			  mainchord = six; 

        cutoffscale = d3
          .scalePow()
          .exponent(0.025)
          .domain([0.028125, 0.0375])
          .range([0.05, 0.25]);

        cutoffscale2 = d3
          .scalePow()
          .exponent(0.025)
          .domain([0.028125, 0.0375])
          .range([0.05, 0.15]);

        cutoffscale3 = d3
          .scalePow()
          .exponent(0.025)
          .domain([0.028125, 0.0375])
          .range([0.25, 0.35]);
        
        cutoffscale3 = d3
          .scalePow()
          .exponent(0.025)
          .domain([0.028125, 0.0375])
          .range([0.35, 0.5]);

        etherealgainseq.values = [0.25, 0, 0.2, 0, 0, 0.3];
        etherealgainseq2.values = [0.6, 0, 0, 0.5, 0, 0.65, 0, 0];
        etherealgainseq3.values = [0.4, 0.5, 0.0, 0.0, 0.3, 0.0];
          //etherealgainseq4.values = [0.9, 0.75, 0.8, 0.0, 0.0, 0.8, 0.8, 0.9, 0.0, 0.9];
        etherealgainseq4.values = [1.5];

        ethereal.attack = beat * 0.1;
        ethereal2.attack = beat * 0.1;
        ethereal3.attack = beat * 0.2;
        ethereal4.attack = beat * 0.3;
  
        ethereal.decay = beat * 2;
        ethereal2.decay = beat * 1;
        ethereal3.decay = beat * 2;
        ethereal4.decay = beat * 1;
  
        ethereal.filterMult = 3;
        ethereal2.filterMult = 1;
        ethereal3.filterMult = 1;
        ethereal4.filterMult = 3;

        etherealseq.values = [  
          Theory.note(mainchord[0], 2),
          Theory.note(mainchord[1], 1),
          Theory.note(mainchord[0], 3),
          Theory.note(mainchord[2], 1),
          Theory.note(mainchord[0], 2),
          Theory.note(mainchord[1], 1),
          Theory.note(mainchord[0], 3),
          Theory.note(mainchord[2], 1),
          Theory.note(mainchord[0], 2),
          Theory.note(mainchord[1], 1),
          Theory.note(mainchord[0], 3),
          Theory.note(mainchord[2], 1),
          Theory.note(mainchord[0], 4),
          Theory.note(mainchord[1], 2),
          Theory.note(mainchord[0], 6),
          Theory.note(mainchord[2], 2) ];

          etherealseq2.values = [
            Theory.note(mainchord[0], 0),
            Theory.note(mainchord[1], 0),
            Theory.note(mainchord[0], 0),
            Theory.note(mainchord[2], 0),
          ];

          etherealseq3.values = [
            Theory.note(mainchord[0], 0),
            Theory.note(mainchord[2], 1),
            Theory.note(mainchord[1], 1),
            Theory.note(mainchord[2], -1),
            Theory.note(mainchord[0], 0),
            Theory.note(mainchord[1], 1),
            Theory.note(mainchord[0], 1),
            Theory.note(mainchord[2], 0),
            Theory.note(mainchord[2], 1),
            Theory.note(mainchord[1], 1),
            Theory.note(mainchord[0], 3),
            Theory.note(mainchord[2], 1),
            Theory.note(mainchord[0], 0),
            Theory.note(mainchord[1], 2),
            Theory.note(mainchord[1], 1),
            Theory.note(mainchord[2], 2),
          ];

          etherealseq4.values = [
            Theory.note(mainchord[2], -1),
            Theory.note(mainchord[0], -2),
            Theory.note(mainchord[0], -1),
            Theory.note(mainchord[2], -2),
            Theory.note(mainchord[0], 0),
            Theory.note(mainchord[1], 0),
            Theory.note(mainchord[2], -3),
            Theory.note(mainchord[2], -1),
        ];


      },
      OnExecute: () => {
        let { beat, uEma } = window;

        delay.delayTime = deltimescale(uEma);
        delay.feedback = delfeedbackscale(uEma);
        delay2.delayTime = deltimescale2(uEma);
        delay2.feedback = delfeedbackscale2(uEma);
  
        filtcutoffseq.timings = [beat * 0.08];
        filtqseq.timings = [beat * 0.025];
        bitcrushseq.timings = [beat * 0.25];
  
        bitcrushseq2.timings = [beat * 0.5];
        filtcutoffseq2.timings = [beat * 0.25];
        filtqseq2.timings = [beat * 0.25];
  
        ethereal.cutoff = cutoffscale(uEma);
        ethereal2.cutoff = cutoffscale2(uEma);
        ethereal3.cutoff = cutoffscale3(uEma);
  
        verb.roomSize = roomscale(uEma);
        //ethereal4.cutoff = cutoffscale4(uEma);
  
        etherealseq.timings = [beat * 0.5];
        //etherealgainseq.timings = [beat * 0.5];
        etherealgainseq.timings = [beat * 0.5];
  
        etherealseq2.timings = [beat * 0.25];
        //etherealgainseq2.timings = [beat * 0.25];
        etherealgainseq2.timings = [beat * 0.25];
  
        etherealseq3.timings = [beat * 0.5];
        //etherealgainseq3.timings = [beat * 0.49];
        etherealgainseq3.timings = [beat * 0.5];
  
        etherealseq4.timings = [beat * 0.25];
        //etherealgainseq4.timings = [beat * 0.24];
        etherealgainseq4.timings = [beat * 0.25];
        etherealcutoffseq4.timing = [beat * 0.25];
      },
    });

    Player.State({
      name: 'Very Slow and Positive',
      instruments: [
        [ethereal, chorus.input],
        [ethereal3, predelay.input],
        [ethereal4, predelay.input],
      ],
      conditions: [() => IsBetween(window.ema, 0.01875, 0.028125)],
      OnEnter: function () {

        console.log('test positive');
        Theory.loadScale('pyle');
			  mainchord = dominant; 

        ethereal.attack = beat * 0.4;
        ethereal2.attack = beat * 0.4;
        ethereal3.attack = beat * 0.5;
        ethereal4.attack = beat * 0.5;
  
        ethereal.decay = beat * 2;
        ethereal2.decay = beat * 6;
        ethereal3.decay = beat * 2;
        ethereal4.decay = beat * 1;
  
        ethereal.filterMult = 1;
        ethereal2.filterMult = 1;
        ethereal3.filterMult = 1;
        ethereal4.filterMult = 1;

        cutoffscale = d3
          .scalePow()
          .exponent(0.025)
          .domain([0, 0.3])
          .range([0.01875, 0.028125]);
        
        cutoffscale3 = d3
          .scalePow()
          .exponent(0.025)
          .domain([0, 0.3])
          .range([0.01875, 0.028125]);
       
        etherealgainseq.values = [0.05, 0, 0.2, 0, 0, 0.1];
        //etherealgainseq.values = [0];
        etherealgainseq2.values = [0];
        //etherealgainseq2.values = [0.15, 0, 0, 0.2, 0, 0.1, 0, 0];

        //etherealgainseq3.values = [0.0];
        etherealgainseq3.values = [0.125, 0.1, 0.0, 0.0, 0.1, 0.0];

        //etherealgainseq4.values = [0];
        etherealgainseq4.values = [0.2, 0.25, 0.3, 0.0, 0.0, 0.15, 0.4, 0.37, 0.0, 0.45];
        etherealcutoffseq4.values = [0.9, 0.11, 0.8, 0.33, 0.5, 0.19, 0.15, 0.7, 0.135, 0.8];
        //etherealcutoffseq4.values = [0.99, 0.01, 0.8, 0.03, 0.25, 0.9, 0.5, 0.7, 0.35, 0.8];



        etherealseq.values = [  
          Theory.note(mainchord[0], 1),
          Theory.note(mainchord[1], 1),
          Theory.note(mainchord[0], 1),
          Theory.note(mainchord[2], 1),
          Theory.note(mainchord[1], 1),
          ];

          etherealseq2.values = [
            Theory.note(mainchord[0], 0),
            Theory.note(mainchord[1], 0),
            Theory.note(mainchord[0], 0),
            Theory.note(mainchord[2], 0),
          ];

          etherealseq3.values = [
            Theory.note(mainchord[0], 0),
            Theory.note(mainchord[2], 1),
            Theory.note(mainchord[1], 1),
            Theory.note(mainchord[2], -1),
          ];

          etherealseq4.values = [
            Theory.note(mainchord[2], -1),
            Theory.note(mainchord[0], -2),
        ];

      },
      OnExecute: () => {
        let { beat, uEma } = window;
 
        delay.delayTime = deltimescale(uEma);
        delay.feedback = delfeedbackscale(uEma);
        delay2.delayTime = deltimescale2(uEma);
        delay2.feedback = delfeedbackscale2(uEma);
  
        filtcutoffseq.timings = [beat * 0.08];
        filtqseq.timings = [beat * 0.025];
        bitcrushseq.timings = [beat * 0.25];
  
        bitcrushseq2.timings = [beat * 0.5];
        filtcutoffseq2.timings = [beat * 0.25];
        filtqseq2.timings = [beat * 0.25];
  
        ethereal.cutoff = cutoffscale(uEma);
        ethereal2.cutoff = cutoffscale2(uEma);
        ethereal3.cutoff = cutoffscale3(uEma);
  
        verb.roomSize = roomscale(uEma);
        //ethereal4.cutoff = cutoffscale4(uEma);
  
        etherealseq.timings = [beat * 2];
        //etherealgainseq.timings = [beat * 0.5];
        etherealgainseq.timings = [beat * 2];
  
        etherealseq2.timings = [beat * 1];
        //etherealgainseq2.timings = [beat * 0.25];
        etherealgainseq2.timings = [beat * 1];
  
        etherealseq3.timings = [beat * 2];
        //etherealgainseq3.timings = [beat * 0.49];
        etherealgainseq3.timings = [beat * 2];
  
        etherealseq4.timings = [beat * 1];
        //etherealgainseq4.timings = [beat * 0.24];
        etherealgainseq4.timings = [beat * 1];
        etherealcutoffseq4.timing = [beat * 1];
      },
    });
    
    Player.State({
      name: 'Super Slow and Positive',
      instruments: [
        [sampler, voiceverb.input],
        [ethereal, chorus.input],
        [ethereal4, predelay.input],
      ],
      conditions: [() => IsBetween(window.ema, 0.009375, 0.01875)],
      OnEnter: function () {


        console.log('test positive');
        Theory.loadScale('pyle');
			  mainchord = subdominant; 

        voiceverb.roomSize = 0.995;
        voiceseq.values = [1.45, 1.6, 1.45, 1.6, 1.45, 2, 1.45, 2.4, 2, 1.6];
        voicegainseq.values = [ 0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.025,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.05,0.0,0.0,0.0,0.0,0.0,0.0,0.075,0.0,0.0,0.0 ];
  

        ethereal.attack = beat * 0.4;
        ethereal2.attack = beat * 0.4;
        ethereal3.attack = beat * 0.5;
        ethereal4.attack = beat * 0.5;
  
        ethereal.decay = beat * 6;
        ethereal2.decay = beat * 6;
        ethereal3.decay = beat * 5;
        ethereal4.decay = beat * 1;
  
        ethereal.filterMult = 1;
        ethereal2.filterMult = 1;
        ethereal3.filterMult = 4;
        ethereal4.filterMult = 1;


        cutoffscale = d3
          .scalePow()
          .exponent(0.025)
          .domain([0.009375, 0.01875])
          .range([0.01, 0.15]);
       
        etherealgainseq.values = [0.8, 0, 0.6, 0, 0, 1.2];
        //etherealgainseq.values = [0];
        etherealgainseq2.values = [0];
        //etherealgainseq2.values = [0.15, 0, 0, 0.2, 0, 0.1, 0, 0];

        etherealgainseq3.values = [0.0];
        //etherealgainseq3.values = [0.125, 0.1, 0.0, 0.0, 0.1, 0.0];

        //etherealgainseq4.values = [0];
        etherealgainseq4.values = [0.5, 0.75, 0.6, 0.0, 0.0, 0.75, 0.8, 0.7, 0.0, 1.5];
        etherealcutoffseq4.values = [0.1, 0.11, 0.01, 0.25, 0.05, 0.19, 0.05, 0.17, 0.135, 0.08];
        //etherealcutoffseq4.values = [0.99, 0.01, 0.8, 0.03, 0.25, 0.9, 0.5, 0.7, 0.35, 0.8];



        etherealseq.values = [  
          Theory.note(mainchord[0], 1),
          Theory.note(mainchord[1], 1),
          Theory.note(mainchord[0], 1),
          Theory.note(mainchord[2], 1),
          Theory.note(mainchord[1], 1),
          ];

          etherealseq2.values = [
            Theory.note(mainchord[0], 0),
            Theory.note(mainchord[1], 0),
            Theory.note(mainchord[0], 0),
            Theory.note(mainchord[2], 0),
          ];

          etherealseq3.values = [
            Theory.note(mainchord[0], 0),
            Theory.note(mainchord[2], 1),
            Theory.note(mainchord[1], 1),
            Theory.note(mainchord[2], -1),
          ];

          etherealseq4.values = [
            Theory.note(mainchord[2], -1),
            Theory.note(mainchord[0], -2),
        ];

      },
      OnExecute: () => {
        let { beat, uEma } = window;

        
        delay.delayTime = deltimescale(uEma);
        delay.feedback = delfeedbackscale(uEma);
        delay2.delayTime = deltimescale2(uEma);
        delay2.feedback = delfeedbackscale2(uEma);

        voiceseq.timings = [ beat * 10 ];
        voicegainseq.timings = [ beat * 10 ];
        
  
        filtcutoffseq.timings = [beat * 0.08];
        filtqseq.timings = [beat * 0.025];
        bitcrushseq.timings = [beat * 0.25];
  
        bitcrushseq2.timings = [beat * 0.5];
        filtcutoffseq2.timings = [beat * 0.25];
        filtqseq2.timings = [beat * 0.25];
  
        ethereal.cutoff = cutoffscale(uEma);
        ethereal2.cutoff = cutoffscale2(uEma);
        ethereal3.cutoff = cutoffscale3(uEma);
  
        verb.roomSize = roomscale(uEma);
        //ethereal4.cutoff = cutoffscale4(uEma);
  
        etherealseq.timings = [beat * 2];
        //etherealgainseq.timings = [beat * 0.5];
        etherealgainseq.timings = [beat * 2];
  
        etherealseq2.timings = [beat * 1];
        //etherealgainseq2.timings = [beat * 0.25];
        etherealgainseq2.timings = [beat * 1];
  
        etherealseq3.timings = [beat * 2];
        //etherealgainseq3.timings = [beat * 0.49];
        etherealgainseq3.timings = [beat * 2];
  
        etherealseq4.timings = [beat * 1];
        //etherealgainseq4.timings = [beat * 0.24];
        etherealgainseq4.timings = [beat * 1];
        etherealcutoffseq4.timing = [beat * 1];
      },
    });

    Player.State({
      name: 'Ultra Slow and Positive',
      instruments: [
        [sampler, voiceverb.input],
        [ethereal, chorus.input],
      ],
      conditions: [() => IsBetween(window.ema, 0, 0.009375)],
      OnEnter: function () {



        console.log('test positive');
        Theory.loadScale('pyle');
			  mainchord = tonic; 

        voiceverb.roomSize = 0.995;
        voiceseq.values = [1.55, 1.75, 1.55, 1.9, 1.55, 1.9, 3.1, 2.25, 1.75];
        voicegainseq.values = [ 0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.0,0.02,0.0,0.0,0.0,0.03,0.0,0.0,0.0,0.0,0.0,0.04,0.0,0.0,0.0 ];
  

        cutoffscale = d3
          .scalePow()
          .exponent(0.025)
          .domain([0, 0.009375])
          .range([0.01, 0.1]);

          ethereal.attack = beat * 2;
          ethereal2.attack = beat * 2;
          ethereal3.attack = beat * 2;
          ethereal4.attack = beat * 2;
    
          ethereal.decay = beat * 2;
          ethereal2.decay = beat * 6;
          ethereal3.decay = beat * 5;
          ethereal4.decay = beat * 4;
    
          ethereal.filterMult = 1;
          ethereal2.filterMult = 1;
          ethereal3.filterMult = 1;
          ethereal4.filterMult = 1;
  
       
        etherealgainseq.values = [1.0, 0, 0.8, 0, 0, 1.0];

        etherealgainseq2.values = [0];
        //etherealgainseq2.values = [0.15, 0, 0, 0.2, 0, 0.1, 0, 0];

        etherealgainseq3.values = [0.0];
        //etherealgainseq3.values = [0.125, 0.1, 0.0, 0.0, 0.1, 0.0];

        etherealgainseq4.values = [0];
        //etherealgainseq4.values = [0.05, 0.125, 0.1, 0.0, 0.0, 0.05, 0.12, 0.17, 0.0, 0.15];
        etherealcutoffseq4.values = [0.09, 0.01, 0.08, 0.03, 0.025, 0.09, 0.05, 0.07, 0.035, 0.08];
        //etherealcutoffseq4.values = [0.99, 0.01, 0.8, 0.03, 0.25, 0.9, 0.5, 0.7, 0.35, 0.8];

        

        etherealseq.values = [  
          Theory.note(mainchord[0], 1),
          Theory.note(mainchord[1], 1),
          Theory.note(mainchord[0], 1),
          Theory.note(mainchord[2], 1),
          Theory.note(mainchord[1], 1),
          ];

          etherealseq2.values = [
            Theory.note(mainchord[0], 0),
            Theory.note(mainchord[1], 0),
            Theory.note(mainchord[0], 0),
            Theory.note(mainchord[2], 0),
          ];

          etherealseq3.values = [
            Theory.note(mainchord[0], 0),
            Theory.note(mainchord[2], 1),
            Theory.note(mainchord[1], 1),
            Theory.note(mainchord[2], -1),
          ];

          etherealseq4.values = [
            Theory.note(mainchord[2], -1),
            Theory.note(mainchord[0], -2),
        ];


      },
      OnExecute: () => {
        let { beat, uEma } = window;

        delay.delayTime = deltimescale(uEma);
        delay.feedback = delfeedbackscale(uEma);
        delay2.delayTime = deltimescale2(uEma);
        delay2.feedback = delfeedbackscale2(uEma);

        voiceseq.timings = [ beat * 15 ];
        voicegainseq.timings = [ beat * 15 ];
        
  
        filtcutoffseq.timings = [beat * 0.08];
        filtqseq.timings = [beat * 0.025];
        bitcrushseq.timings = [beat * 0.25];
  
        bitcrushseq2.timings = [beat * 0.5];
        filtcutoffseq2.timings = [beat * 0.25];
        filtqseq2.timings = [beat * 0.25];
  
        ethereal.cutoff = cutoffscale(uEma);
        ethereal2.cutoff = cutoffscale2(uEma);
        ethereal3.cutoff = cutoffscale3(uEma);
  
        verb.roomSize = roomscale(uEma);
        //ethereal4.cutoff = cutoffscale4(uEma);
  
        etherealseq.timings = [beat * 2];
        //etherealgainseq.timings = [beat * 0.5];
        etherealgainseq.timings = [beat * 2];
  
        etherealseq2.timings = [beat * 1];
        //etherealgainseq2.timings = [beat * 0.25];
        etherealgainseq2.timings = [beat * 1];
  
        etherealseq3.timings = [beat * 2];
        //etherealgainseq3.timings = [beat * 0.49];
        etherealgainseq3.timings = [beat * 2];
  
        etherealseq4.timings = [beat * 1];
        //etherealgainseq4.timings = [beat * 0.24];
        etherealgainseq4.timings = [beat * 1];
        etherealcutoffseq4.timing = [beat * 1];
      },
    });

    Player.State({
      name: 'Ultra Slow and Negative',
      instruments: [
        [ninBass,  ninCrush.input ],
        [ninBass],
        [ethereal, delay.input],
      ],
      conditions: [() => IsBetween(window.ema, -0.009375, 0)],
      OnEnter: function () {
        console.log('test negative');
        Theory.loadScale('astro');
			  mainchord = tonic; 

        ninCrushScale = d3
        .scalePow()
        .exponent(0.0075)
        .domain([-0.009375, 0])
        .range([0.25, 0.75]);
  
        

          ninBass.filterMult = 1;

          ninBassSeq.values = [
            Theory.note(mainchord[0], -2),
            Theory.note(mainchord[1], -2),
            Theory.note(mainchord[0], -2),
          ];

          ninBassGainSeq.values = [0.6, 0, 0.6, 0, 0.5];
          ninBass.cutoff = 0.1;

          ethereal.attack = beat * 0.001;
          ethereal2.attack = beat * 4;
          ethereal3.attack = beat * 2;
          ethereal4.attack = beat * 2;
    
          ethereal.decay = beat * 6;
          ethereal2.decay = beat * 6;
          ethereal3.decay = beat * 5;
          ethereal4.decay = beat * 4;
    
          ethereal.filterMult = 1;
          ethereal2.filterMult = 1;
          ethereal3.filterMult = 1;
          ethereal4.filterMult = 1;

        cutoffscale = d3
          .scalePow()
          .exponent(0.5)
          .domain([0, 0.1])
          .range([0.01, 0.95]);

        etherealseq.values = [  
          Theory.note(mainchord[0], 2),
          Theory.note(mainchord[1], 1),
          Theory.note(mainchord[0], 3),
          Theory.note(mainchord[2], 1),
          Theory.note(mainchord[0], 2),
          Theory.note(mainchord[1], 1),
          Theory.note(mainchord[0], 3),
          Theory.note(mainchord[2], 1),
          Theory.note(mainchord[0], 2),
          Theory.note(mainchord[1], 1),
          Theory.note(mainchord[0], 3),
          Theory.note(mainchord[2], 1),
          Theory.note(mainchord[0], 3),
          Theory.note(mainchord[1], 2),
          Theory.note(mainchord[0], 3),
          Theory.note(mainchord[2], 2) ];

          etherealseq2.values = [
            Theory.note(mainchord[0], 0),
            Theory.note(mainchord[1], 0),
            Theory.note(mainchord[0], 0),
            Theory.note(mainchord[2], 0),
          ];

          etherealseq3.values = [
            Theory.note(mainchord[0], 0),
            Theory.note(mainchord[2], 1),
            Theory.note(mainchord[1], 1),
            Theory.note(mainchord[2], -1),
            Theory.note(mainchord[0], 0),
            Theory.note(mainchord[1], 1),
            Theory.note(mainchord[0], 1),
            Theory.note(mainchord[2], 0),
            Theory.note(mainchord[2], 1),
            Theory.note(mainchord[1], 1),
            Theory.note(mainchord[0], 3),
            Theory.note(mainchord[2], 1),
            Theory.note(mainchord[0], 0),
            Theory.note(mainchord[1], 2),
            Theory.note(mainchord[1], 1),
            Theory.note(mainchord[2], 2),
          ];

          etherealseq4.values = [
            Theory.note(mainchord[2], -1),
            Theory.note(mainchord[0], -2),
            Theory.note(mainchord[0], -1),
            Theory.note(mainchord[2], -2),
            Theory.note(mainchord[0], 0),
            Theory.note(mainchord[1], 0),
            Theory.note(mainchord[2], -3),
            Theory.note(mainchord[2], -1),
        ];

      },
      OnExecute: () => {
        let {beat, uEma} = window;



        ninCrushScale = d3
        .scalePow()
        .exponent(0.0075)
        .domain([-0.009375, 0])
        .range([0.001, 0.5]);

        ninCrush.sampleRate = ninCrushScale(uEma);

        ninBassSeq.timings = [beat * 2];
        ninBassGainSeq.timings = [beat * 2];
        ninBass.release = beat * 1.95;
        ninBassSeq.timings = [beat * 2];
    
        delay.delayTime = deltimescale(uEma);
        delay.feedback = delfeedbackscale(uEma);
        delay2.delayTime = deltimescale2(uEma);
        delay2.feedback = delfeedbackscale2(uEma);

        filtcutoffseq.timings = [beat * 0.03125];
        filtqseq.timings = [beat * 0.0156125];
        bitcrushseq.timings = [beat * 0.25];

        bitcrushseq2.timings = [beat * 0.5];
        filtcutoffseq2.timings = [beat * 0.25];
        filtqseq2.timings = [beat * 0.25];

        ethereal.cutoff = cutoffscale(uEma);
        ethereal2.cutoff = cutoffscale2(uEma);
        ethereal3.cutoff = cutoffscale3(uEma);

        verb.roomSize = roomscale(uEma);
        //ethereal4.cutoff = cutoffscale4(uEma);
      
        etherealseq.timings = [beat * 1];
        //etherealgainseq.timings = [beat * 1];
        etherealgainseq.timings = [beat * 0.125];

        etherealseq2.timings = [beat * 1];
        //etherealgainseq2.timings = [beat * 1];
        etherealgainseq2.timings = [beat * 0.25];

        etherealseq3.timings = [beat * 0.98];
        //etherealgainseq3.timings = [beat * 0.98];
        etherealgainseq3.timings = [beat * 0.125];

        etherealseq4.timings = [beat * 1.01];
        //etherealgainseq4.timings = [beat * 1.01];

        etherealgainseq4.timings = [beat * 0.25];

        etherealcutoffseq4.timing = [beat * 0.5];
        },
    });

    Player.State({
      name: 'Super Slow and Negative',
      instruments: [
        [ninBass,  ninCrush.input ],
        [ninBass],
        [ethereal, delay.input],
        [ethereal4, delay2.input],
      ],
      conditions: [() => IsBetween(window.ema, -0.01875, -0.009375)],
      OnEnter: function () {
        console.log('test negative');
        Theory.loadScale('astro');
			  mainchord = two; 

        cutoffscale = d3
          .scalePow()
          .exponent(0.5)
          .domain([0, 0.1])
          .range([0.01, 0.95]);

        ninCrushScale = d3
        .scalePow()
        .exponent(0.0075)
        .domain([-0.01875, -0.009375])
        .range([0.25, 0.6]);
        
        


          ninBassSeq.values = [
            Theory.note(mainchord[0], -2),
            Theory.note(mainchord[1], -2),
            Theory.note(mainchord[0], -2),
          ];

          ninBassGainSeq.values = [0.2, 0, 0.2, 0, 0.2];
          ninBass.cutoff = 0.5;
          ninBass.filterMult = 1.5;

          ethereal.attack = beat * 0.001;
          ethereal2.attack = beat * 1;
          ethereal3.attack = beat * 1;
          ethereal4.attack = beat * 0.001;
    
          ethereal.decay = beat * 3;
          ethereal2.decay = beat * 3;
          ethereal3.decay = beat * 2;
          ethereal4.decay = beat * 2;
    
          ethereal.filterMult = 3;
          ethereal2.filterMult = 1;
          ethereal3.filterMult = 4;
          ethereal4.filterMult = 12;

        etherealseq.values = [  
          Theory.note(mainchord[0], 2),
          Theory.note(mainchord[1], 1),
          Theory.note(mainchord[0], 3),
          Theory.note(mainchord[2], 1),
          Theory.note(mainchord[0], 2),
          Theory.note(mainchord[1], 1),
          Theory.note(mainchord[0], 3),
          Theory.note(mainchord[2], 1),
          Theory.note(mainchord[0], 2),
          Theory.note(mainchord[1], 1),
          Theory.note(mainchord[0], 3),
          Theory.note(mainchord[2], 1),
          Theory.note(mainchord[0], 3),
          Theory.note(mainchord[1], 2),
          Theory.note(mainchord[0], 3),
          Theory.note(mainchord[2], 2) ];

          etherealseq2.values = [
            Theory.note(mainchord[0], 0),
            Theory.note(mainchord[1], 0),
            Theory.note(mainchord[0], 0),
            Theory.note(mainchord[2], 0),
          ];

          etherealseq3.values = [
            Theory.note(mainchord[0], 0),
            Theory.note(mainchord[2], 1),
            Theory.note(mainchord[1], 1),
            Theory.note(mainchord[2], -1),
            Theory.note(mainchord[0], 0),
            Theory.note(mainchord[1], 1),
            Theory.note(mainchord[0], 1),
            Theory.note(mainchord[2], 0),
            Theory.note(mainchord[2], 1),
            Theory.note(mainchord[1], 1),
            Theory.note(mainchord[0], 3),
            Theory.note(mainchord[2], 1),
            Theory.note(mainchord[0], 0),
            Theory.note(mainchord[1], 2),
            Theory.note(mainchord[1], 1),
            Theory.note(mainchord[2], 2),
          ];

          etherealseq4.values = [
            Theory.note(mainchord[2], -1),
            Theory.note(mainchord[0], -2),
            Theory.note(mainchord[0], -1),
            Theory.note(mainchord[2], -2),
            Theory.note(mainchord[0], 0),
            Theory.note(mainchord[1], 0),
            Theory.note(mainchord[2], -3),
            Theory.note(mainchord[2], -1),
        ];

      },
      OnExecute: () => {
        let {beat, uEma} = window;

        ninCrush.sampleRate = ninCrushScale(uEma);
        ninBassSeq.timings = [beat * 1];
        ninBassGainSeq.timings = [beat * 1];
        ninBass.release = beat * 8;
        ninBassSeq.timings = [beat * 1];
    

        delay.delayTime = deltimescale(uEma);
        delay.feedback = delfeedbackscale(uEma);
        delay2.delayTime = deltimescale2(uEma);
        delay2.feedback = delfeedbackscale2(uEma);

        filtcutoffseq.timings = [beat * 0.03125];
        filtqseq.timings = [beat * 0.0156125];
        bitcrushseq.timings = [beat * 0.25];

        bitcrushseq2.timings = [beat * 0.5];
        filtcutoffseq2.timings = [beat * 0.25];
        filtqseq2.timings = [beat * 0.25];

        ethereal.cutoff = cutoffscale(uEma);
        ethereal2.cutoff = cutoffscale2(uEma);
        ethereal3.cutoff = cutoffscale3(uEma);

        verb.roomSize = roomscale(uEma);
        //ethereal4.cutoff = cutoffscale4(uEma);
      
        etherealseq.timings = [beat * 1];
        //etherealgainseq.timings = [beat * 1];
        etherealgainseq.timings = [beat * 0.125];

        etherealseq2.timings = [beat * 1];
        //etherealgainseq2.timings = [beat * 1];
        etherealgainseq2.timings = [beat * 0.25];

        etherealseq3.timings = [beat * 0.98];
        //etherealgainseq3.timings = [beat * 0.98];
        etherealgainseq3.timings = [beat * 0.125];

        etherealseq4.timings = [beat * 1.01];
        //etherealgainseq4.timings = [beat * 1.01];

        etherealgainseq4.timings = [beat * 0.25];

        etherealcutoffseq4.timing = [beat * 0.5];
        },
    });

    Player.State({
      name: 'Very Slow and Negative',
      instruments: [
        [ninBass,  ninCrush.input ],
        [ninBass],
        [ethereal, delay.input],
        [ethereal3, delay2.input],
        [ethereal4, delay2.input],
      ],
      conditions: [() => IsBetween(window.ema, -0.028125, -0.01875)],
      OnEnter: function () {
        console.log('test negative');
        Theory.loadScale('astro');
			  mainchord = dominant; 


        ninCrushScale = d3
        .scalePow()
        .exponent(0.0075)
        .domain([-0.028125, -0.01875])
        .range([0.25, 0.55]);
        
          ninBassSeq.values = [
            Theory.note(mainchord[0], -2),
            Theory.note(mainchord[1], -2),
            Theory.note(mainchord[0], -2),
          ];

          ninBassGainSeq.values = [0.3, 0, 0.3, 0, 0.3];
          ninBass.cutoff = 0.6;
          ninBass.filterMult = 2;

          ethereal.attack = beat * 0.001;
          ethereal2.attack = beat * 0.5;
          ethereal3.attack = beat * 0.001;
          ethereal4.attack = beat * 0.001;
    
          ethereal.decay = beat * 8;
          ethereal2.decay = beat * 8;
          ethereal3.decay = beat * 12;
          ethereal4.decay = beat * 18;
    
          ethereal.filterMult = 4;
          ethereal2.filterMult = 1;
          ethereal3.filterMult = 8;
          ethereal4.filterMult = 12;

        cutoffscale = d3
          .scalePow()
          .exponent(0.5)
          .domain([0, 0.1])
          .range([0.01, 0.95]);

        etherealseq.values = [  
          Theory.note(mainchord[0], 2),
          Theory.note(mainchord[1], 1),
          Theory.note(mainchord[0], 3),
          Theory.note(mainchord[2], 1),
          Theory.note(mainchord[0], 2),
          Theory.note(mainchord[1], 1),
          Theory.note(mainchord[0], 3),
          Theory.note(mainchord[2], 1),
          Theory.note(mainchord[0], 2),
          Theory.note(mainchord[1], 1),
          Theory.note(mainchord[0], 3),
          Theory.note(mainchord[2], 1),
          Theory.note(mainchord[0], 3),
          Theory.note(mainchord[1], 2),
          Theory.note(mainchord[0], 3),
          Theory.note(mainchord[2], 2) ];

          etherealseq2.values = [
            Theory.note(mainchord[0], 0),
            Theory.note(mainchord[1], 0),
            Theory.note(mainchord[0], 0),
            Theory.note(mainchord[2], 0),
          ];

          etherealseq3.values = [
            Theory.note(mainchord[0], 0),
            Theory.note(mainchord[2], 1),
            Theory.note(mainchord[1], 1),
            Theory.note(mainchord[2], -1),
            Theory.note(mainchord[0], 0),
            Theory.note(mainchord[1], 1),
            Theory.note(mainchord[0], 1),
            Theory.note(mainchord[2], 0),
            Theory.note(mainchord[2], 1),
            Theory.note(mainchord[1], 1),
            Theory.note(mainchord[0], 3),
            Theory.note(mainchord[2], 1),
            Theory.note(mainchord[0], 0),
            Theory.note(mainchord[1], 2),
            Theory.note(mainchord[1], 1),
            Theory.note(mainchord[2], 2),
          ];

          etherealseq4.values = [
            Theory.note(mainchord[2], -1),
            Theory.note(mainchord[0], -2),
            Theory.note(mainchord[0], -1),
            Theory.note(mainchord[2], -2),
            Theory.note(mainchord[0], 0),
            Theory.note(mainchord[1], 0),
            Theory.note(mainchord[2], -3),
            Theory.note(mainchord[2], -1),
        ];

      },
      OnExecute: () => {
        let {beat, uEma} = window;

        ninCrush.sampleRate = ninCrushScale(uEma);
        ninBassSeq.timings = [beat * 0.5];
        ninBassGainSeq.timings = [beat * 0.5];
        ninBass.release = beat * 3;
        ninBassSeq.timings = [beat * 0.5];

        delay.delayTime = deltimescale(uEma);
        delay.feedback = delfeedbackscale(uEma);
        delay2.delayTime = deltimescale2(uEma);
        delay2.feedback = delfeedbackscale2(uEma);

        filtcutoffseq.timings = [beat * 0.03125];
        filtqseq.timings = [beat * 0.0156125];
        bitcrushseq.timings = [beat * 0.25];

        bitcrushseq2.timings = [beat * 0.5];
        filtcutoffseq2.timings = [beat * 0.25];
        filtqseq2.timings = [beat * 0.25];

        ethereal.cutoff = cutoffscale(uEma);
        ethereal2.cutoff = cutoffscale2(uEma);
        ethereal3.cutoff = cutoffscale3(uEma);

        verb.roomSize = roomscale(uEma);
        //ethereal4.cutoff = cutoffscale4(uEma);
      
        etherealseq.timings = [beat * 1];
        //etherealgainseq.timings = [beat * 1];
        etherealgainseq.timings = [beat * 0.125];

        etherealseq2.timings = [beat * 1];
        //etherealgainseq2.timings = [beat * 1];
        etherealgainseq2.timings = [beat * 0.25];

        etherealseq3.timings = [beat * 0.98];
        //etherealgainseq3.timings = [beat * 0.98];
        etherealgainseq3.timings = [beat * 0.125];

        etherealseq4.timings = [beat * 1.01];
        //etherealgainseq4.timings = [beat * 1.01];

        etherealgainseq4.timings = [beat * 0.25];

        etherealcutoffseq4.timing = [beat * 0.5];
        },
    });

    Player.State({
      name: 'Slow and Negative',
      instruments: [
        [ninBass,  ninCrush.input ],
        [ninBass],
        [ethereal, delay.input],
        [ethereal2, delay.input],
        [ethereal3, delay2.input],
        [ethereal4, delay2.input],
      ],
      conditions: [() => IsBetween(window.ema, -0.0375, -0.028125)],
      OnEnter: function () {
        console.log('test negative');
        Theory.loadScale('astro');
			  mainchord = three; 

        cutoffscale = d3
          .scalePow()
          .exponent(0.5)
          .domain([0, 0.1])
          .range([0.01, 0.95]);


        ninCrushScale = d3
          .scalePow()
          .exponent(0.0075)
          .domain([-0.0375, -0.028125])
          .range([0.1, 0.55]);
        
          ninBassSeq.values = [
            Theory.note(mainchord[0], -2),
            Theory.note(mainchord[1], -2),
            Theory.note(mainchord[0], -2),
            Theory.note(mainchord[0], -2),
            Theory.note(mainchord[1], -2),
            Theory.note(mainchord[0], -2),
            Theory.note(mainchord[0], -2),
          ];

          ninBassGainSeq.values = [0.4, 0.5,0.0, 0.3, 0.3,0.0];
          ninBass.cutoff = 0.6;
          ninBass.filterMult = 3;

          ethereal.attack = beat * 0.001;
          ethereal2.attack = beat * 0.001;
          ethereal3.attack = beat * 0.001;
          ethereal4.attack = beat * 0.001;
    
          ethereal.decay = beat * 3;
          ethereal2.decay = beat * 3;
          ethereal3.decay = beat * 15;
          ethereal4.decay = beat * 18;
    
          ethereal.filterMult = 8;
          ethereal2.filterMult = 1;
          ethereal3.filterMult = 16;
          ethereal4.filterMult = 25;

        etherealseq.values = [  
          Theory.note(mainchord[0], 2),
          Theory.note(mainchord[1], 1),
          Theory.note(mainchord[0], 3),
          Theory.note(mainchord[2], 1),
          Theory.note(mainchord[0], 2),
          Theory.note(mainchord[1], 1),
          Theory.note(mainchord[0], 3),
          Theory.note(mainchord[2], 1),
          Theory.note(mainchord[0], 2),
          Theory.note(mainchord[1], 1),
          Theory.note(mainchord[0], 3),
          Theory.note(mainchord[2], 1),
          Theory.note(mainchord[0], 4),
          Theory.note(mainchord[1], 2),
          Theory.note(mainchord[0], 6),
          Theory.note(mainchord[2], 2) ];

          etherealseq2.values = [
            Theory.note(mainchord[0], 0),
            Theory.note(mainchord[1], 2),
            Theory.note(mainchord[0], 0),
            Theory.note(mainchord[2], 3),
            Theory.note(mainchord[0], 0),
            Theory.note(mainchord[1], 0),
            Theory.note(mainchord[0], 3),
            Theory.note(mainchord[2], 0),
            Theory.note(mainchord[0], 0),
            Theory.note(mainchord[1], 2),
            Theory.note(mainchord[0], 0),
            Theory.note(mainchord[2], 3),
            Theory.note(mainchord[0], 0),
            Theory.note(mainchord[1], 3),
            Theory.note(mainchord[0], 3),
            Theory.note(mainchord[2], 0),
          ];

          etherealseq3.values = [
            Theory.note(mainchord[0], 0),
            Theory.note(mainchord[2], 1),
            Theory.note(mainchord[1], 1),
            Theory.note(mainchord[2], -1),
            Theory.note(mainchord[0], 0),
            Theory.note(mainchord[1], 1),
            Theory.note(mainchord[0], 1),
            Theory.note(mainchord[2], 0),
            Theory.note(mainchord[2], 1),
            Theory.note(mainchord[1], 1),
            Theory.note(mainchord[0], 3),
            Theory.note(mainchord[2], 1),
            Theory.note(mainchord[0], 0),
            Theory.note(mainchord[1], 2),
            Theory.note(mainchord[1], 1),
            Theory.note(mainchord[2], 2),
          ];

          etherealseq4.values = [
            Theory.note(mainchord[2], -1),
            Theory.note(mainchord[0], -2),
            Theory.note(mainchord[0], -1),
            Theory.note(mainchord[2], -2),
            Theory.note(mainchord[0], 0),
            Theory.note(mainchord[1], 0),
            Theory.note(mainchord[2], -3),
            Theory.note(mainchord[2], -1),
        ];

      },
      OnExecute: () => {
        let {beat, uEma} = window;

        ninCrush.sampleRate = ninCrushScale(uEma);
        ninBassSeq.timings = [beat * 0.5];
        ninBassGainSeq.timings = [beat * 0.5];
        ninBass.release = beat * 2;
        ninBassSeq.timings = [beat * 0.5];

        delay.delayTime = deltimescale(uEma);
        delay.feedback = delfeedbackscale(uEma);
        delay2.delayTime = deltimescale2(uEma);
        delay2.feedback = delfeedbackscale2(uEma);

        filtcutoffseq.timings = [beat * 0.03125];
        filtqseq.timings = [beat * 0.0156125];
        bitcrushseq.timings = [beat * 0.25];

        bitcrushseq2.timings = [beat * 0.5];
        filtcutoffseq2.timings = [beat * 0.25];
        filtqseq2.timings = [beat * 0.25];

        ethereal.cutoff = cutoffscale(uEma);
        ethereal2.cutoff = cutoffscale2(uEma);
        ethereal3.cutoff = cutoffscale3(uEma);

        verb.roomSize = roomscale(uEma);
        //ethereal4.cutoff = cutoffscale4(uEma);
      
        etherealseq.timings = [beat * 1];
        //etherealgainseq.timings = [beat * 1];
        etherealgainseq.timings = [beat * 0.125];

        etherealseq2.timings = [beat * 1];
        //etherealgainseq2.timings = [beat * 1];
        etherealgainseq2.timings = [beat * 0.25];

        etherealseq3.timings = [beat * 0.98];
        //etherealgainseq3.timings = [beat * 0.98];
        etherealgainseq3.timings = [beat * 0.125];

        etherealseq4.timings = [beat * 1.01];
        //etherealgainseq4.timings = [beat * 1.01];

        etherealgainseq4.timings = [beat * 0.25];

        etherealcutoffseq4.timing = [beat * 0.5];
        },
    });

    Player.State({
      name: 'Moderate and Negative',
      instruments: [
        [ninBass,  ninCrush.input ],
        [ninBass],
        [ethereal, delay.input],
        [ethereal2, delay.input],
        [ethereal3, delay2.input],
        [ethereal4, delay2.input],
      ],
      conditions: [() => IsBetween(window.ema, -0.046875, -0.0375)],
      OnEnter: function () {
        console.log('test negative');
        Theory.loadScale('astro');
			  mainchord = six; 

        ninCrushScale = d3
          .scalePow()
          .exponent(0.0075)
          .domain([-0.046875, -0.0375])
          .range([0.05, 0.65]);
        
          ninBassSeq.values = [
            Theory.note(mainchord[0], -2),
            Theory.note(mainchord[1], -2),
            Theory.note(mainchord[0], -2),
            Theory.note(mainchord[0], -2),
            Theory.note(mainchord[1], -2),
            Theory.note(mainchord[0], -2),
            Theory.note(mainchord[0], -2),
          ];

          ninBassGainSeq.values = [0.4, 0.5,0.0, 0.3, 0.3,0.0];
          ninBass.cutoff = 0.75;
          ninBass.filterMult = 5;

        cutoffscale = d3
          .scalePow()
          .exponent(0.5)
          .domain([0, 0.1])
          .range([0.01, 0.95]);

          ethereal.attack = beat * 0.001;
          ethereal2.attack = beat * 0.001;
          ethereal3.attack = beat * 0.00125;
          ethereal4.attack = beat * 0.00125;
    
          ethereal.decay = beat * 1;
          ethereal2.decay = beat * 1;
          ethereal3.decay = beat * 1;
          ethereal4.decay = beat * 1;
    
          ethereal.filterMult = 16;
          ethereal2.filterMult = 1;
          ethereal3.filterMult = 32;
          ethereal4.filterMult = 28;

        etherealseq.values = [  
          Theory.note(mainchord[0], 2),
          Theory.note(mainchord[1], 2),
          Theory.note(mainchord[0], 2),
          Theory.note(mainchord[2], 1),
          Theory.note(mainchord[0], 2),
          Theory.note(mainchord[1], 1),
          Theory.note(mainchord[0], 2),
          Theory.note(mainchord[2], 1),
          Theory.note(mainchord[0], 2),
          Theory.note(mainchord[1], 1),
          Theory.note(mainchord[0], 2),
          Theory.note(mainchord[2], 1),
          Theory.note(mainchord[0], 2),
          Theory.note(mainchord[1], 2),
          Theory.note(mainchord[0], 2),
          Theory.note(mainchord[2], 2) ];

          etherealseq2.values = [
            Theory.note(mainchord[0], 0),
            Theory.note(mainchord[1], 3),
            Theory.note(mainchord[0], 0),
            Theory.note(mainchord[2], 3),
          ];

          etherealseq3.values = [
            Theory.note(mainchord[0], 0),
            Theory.note(mainchord[2], 1),
            Theory.note(mainchord[1], 1),
            Theory.note(mainchord[2], -1),
            Theory.note(mainchord[0], 0),
            Theory.note(mainchord[1], 4),
            Theory.note(mainchord[0], 1),
            Theory.note(mainchord[2], 0),
            Theory.note(mainchord[2], 1),
            Theory.note(mainchord[1], 1),
            Theory.note(mainchord[0], 3),
            Theory.note(mainchord[2], 1),
            Theory.note(mainchord[0], 0),
            Theory.note(mainchord[1], 2),
            Theory.note(mainchord[1], 4),
            Theory.note(mainchord[2], 2),
          ];

          etherealseq4.values = [
            Theory.note(mainchord[2], -1),
            Theory.note(mainchord[0], -2),
            Theory.note(mainchord[0], -1),
            Theory.note(mainchord[2], -2),
            Theory.note(mainchord[0], 3),
            Theory.note(mainchord[1], 3),
            Theory.note(mainchord[2], -3),
            Theory.note(mainchord[2], -1),
        ];

      },
      OnExecute: () => {
        let {beat, uEma} = window;

        ninCrush.sampleRate = ninCrushScale(uEma);
        ninBassSeq.timings = [beat * 0.25];
        ninBassGainSeq.timings = [beat * 0.25];
        ninBass.release = beat * 5;
        ninBassSeq.timings = [beat * 0.25];

        delay.delayTime = deltimescale(uEma);
        delay.feedback = delfeedbackscale(uEma);
        delay2.delayTime = deltimescale2(uEma);
        delay2.feedback = delfeedbackscale2(uEma);

        filtcutoffseq.timings = [beat * 0.03125];
        filtqseq.timings = [beat * 0.0156125];
        bitcrushseq.timings = [beat * 0.25];

        bitcrushseq2.timings = [beat * 0.5];
        filtcutoffseq2.timings = [beat * 0.25];
        filtqseq2.timings = [beat * 0.25];

        ethereal.cutoff = cutoffscale(uEma);
        ethereal2.cutoff = cutoffscale2(uEma);
        ethereal3.cutoff = cutoffscale3(uEma);

        verb.roomSize = roomscale(uEma);
        //ethereal4.cutoff = cutoffscale4(uEma);
      
        etherealseq.timings = [beat * 1];
        //etherealgainseq.timings = [beat * 1];
        etherealgainseq.timings = [beat * 0.125];

        etherealseq2.timings = [beat * 1];
        //etherealgainseq2.timings = [beat * 1];
        etherealgainseq2.timings = [beat * 0.25];

        etherealseq3.timings = [beat * 0.98];
        //etherealgainseq3.timings = [beat * 0.98];
        etherealgainseq3.timings = [beat * 0.125];

        etherealseq4.timings = [beat * 1.01];
        //etherealgainseq4.timings = [beat * 1.01];

        etherealgainseq4.timings = [beat * 0.25];

        etherealcutoffseq4.timing = [beat * 0.5];
        },
    });

    Player.State({
      name: 'Fast and Negative',
      instruments: [
        [ninBass,  ninCrush.input ],
        [ninBass],
        [ethereal, delay.input],
        [ethereal2, delay.input],
        [ethereal3, delay2.input],
        [ethereal4, delay2.input],
      ],
      conditions: [() => IsBetween(window.ema, -0.05625, -0.046875)],
      OnEnter: function () {
        console.log('test negative');
        Theory.loadScale('astro');
			  mainchord = subdominant; 



        cutoffscale = d3
          .scalePow()
          .exponent(0.5)
          .domain([0, 0.3])
          .range([0.01, 0.95]);

        ninCrushScale = d3
          .scalePow()
          .exponent(0.0075)
          .domain([-0.05625, -0.046875])
          .range([0.04, 0.7]);
        
          ninBassSeq.values = [
            Theory.note(mainchord[0], -2),
            Theory.note(mainchord[1], -2),
            Theory.note(mainchord[0], -2),
            Theory.note(mainchord[0], -2),
            Theory.note(mainchord[1], -2),
            Theory.note(mainchord[0], -2),
            Theory.note(mainchord[0], -2),
          ];

          ninBassGainSeq.values = [0.4, 0.5,0.4,0.0, 0.3, 0.3,0.0];
          ninBass.cutoff = 0.8;
          ninBass.filterMult = 6;

          ethereal.attack = beat * 0.001;
          ethereal2.attack = beat * 0.01;
          ethereal3.attack = beat * 0.00125;
          ethereal4.attack = beat * 0.0125;
    
          ethereal.decay = beat * 2.5;
          ethereal2.decay = beat * 2.75;
          ethereal3.decay = beat * 2.6;
          ethereal4.decay = beat * 2.33;
    
          ethereal.filterMult = 32;
          ethereal2.filterMult = 1;
          ethereal3.filterMult = 48;
          ethereal4.filterMult = 36;

        etherealseq.values = [  
          Theory.note(mainchord[0], 1),
          Theory.note(mainchord[1], 1),
          Theory.note(mainchord[0], 1),
          Theory.note(mainchord[2], 1),
          Theory.note(mainchord[0], 2),
          Theory.note(mainchord[1], 1),
          Theory.note(mainchord[0], 3),
          Theory.note(mainchord[2], 1),
          Theory.note(mainchord[0], 2),
          Theory.note(mainchord[1], 1),
          Theory.note(mainchord[0], 3),
          Theory.note(mainchord[2], 1),
          Theory.note(mainchord[0], 3),
          Theory.note(mainchord[1], 1),
          Theory.note(mainchord[0], 3),
          Theory.note(mainchord[2], 3) ];

          etherealseq2.values = [
            Theory.note(mainchord[0], 0),
            Theory.note(mainchord[1], 2),
            Theory.note(mainchord[0], 2),
            Theory.note(mainchord[2], 1),
            Theory.note(mainchord[0], 3),
            Theory.note(mainchord[1], 0),
            Theory.note(mainchord[0], 2),
            Theory.note(mainchord[2], 0),
          ];

          etherealseq3.values = [
            Theory.note(mainchord[0], 0),
            Theory.note(mainchord[2], 1),
            Theory.note(mainchord[1], 1),
            Theory.note(mainchord[2], -1),
            Theory.note(mainchord[0], 0),
            Theory.note(mainchord[1], -1),
            Theory.note(mainchord[0], -1),
            Theory.note(mainchord[2], 0),
            Theory.note(mainchord[2], -1),
            Theory.note(mainchord[1], -1),
            Theory.note(mainchord[0], -3),
            Theory.note(mainchord[2], 1),
            Theory.note(mainchord[0], 0),
            Theory.note(mainchord[1], -2),
            Theory.note(mainchord[1], -1),
            Theory.note(mainchord[2], 2),
          ];

          etherealseq4.values = [
            Theory.note(mainchord[2], 1),
            Theory.note(mainchord[0], -2),
            Theory.note(mainchord[0], -1),
            Theory.note(mainchord[2], 2),
            Theory.note(mainchord[0], 0),
            Theory.note(mainchord[1], 0),
            Theory.note(mainchord[2], -3),
            Theory.note(mainchord[2], -1),
        ];

      },
      OnExecute: () => {
        let {beat, uEma} = window;

        ninCrush.sampleRate = ninCrushScale(uEma);
        ninBassSeq.timings = [beat * 0.25];
        ninBassGainSeq.timings = [beat * 0.25];
        ninBass.release = beat * 3;
        ninBassSeq.timings = [beat * 0.25];

        delay.delayTime = deltimescale(uEma);
        delay.feedback = delfeedbackscale(uEma);
        delay2.delayTime = deltimescale2(uEma);
        delay2.feedback = delfeedbackscale2(uEma);

        filtcutoffseq.timings = [beat * 0.03125];
        filtqseq.timings = [beat * 0.0156125];
        bitcrushseq.timings = [beat * 0.25];

        bitcrushseq2.timings = [beat * 0.5];
        filtcutoffseq2.timings = [beat * 0.25];
        filtqseq2.timings = [beat * 0.25];

        ethereal.cutoff = cutoffscale(uEma);
        ethereal2.cutoff = cutoffscale2(uEma);
        ethereal3.cutoff = cutoffscale3(uEma);

        verb.roomSize = roomscale(uEma);
        //ethereal4.cutoff = cutoffscale4(uEma);
      
        etherealseq.timings = [beat * 1];
        //etherealgainseq.timings = [beat * 1];
        etherealgainseq.timings = [beat * 0.125];

        etherealseq2.timings = [beat * 1];
        //etherealgainseq2.timings = [beat * 1];
        etherealgainseq2.timings = [beat * 0.25];

        etherealseq3.timings = [beat * 0.98];
        //etherealgainseq3.timings = [beat * 0.98];
        etherealgainseq3.timings = [beat * 0.125];

        etherealseq4.timings = [beat * 1.01];
        //etherealgainseq4.timings = [beat * 1.01];

        etherealgainseq4.timings = [beat * 0.25];

        etherealcutoffseq4.timing = [beat * 0.5];
        },
    });

    Player.State({
      name: 'Very Fast and Negative',
      instruments: [
        [sampler, voicecrush.input],
        [ninBass,  ninCrush.input ],
        [ninBass],
        [ethereal, delay.input],
        [ethereal2, delay.input],
        [ethereal3, delay2.input],
        [ethereal4, delay2.input],
      ],
      conditions: [() => IsBetween(window.ema, -0.065625, -0.05625)],
      OnEnter: function () {
        console.log('test negative');
        Theory.loadScale('astro');
			  mainchord = dominant; 

        cutoffscale = d3
          .scalePow()
          .exponent(0.5)
          .domain([0, 0.1])
          .range([0.01, 0.95]);

        ninCrushScale = d3
          .scalePow()
          .exponent(0.0075)
          .domain([-0.065625, -0.05625])
          .range([0.03, 0.7]);

        voicecrush.repitchMin = .75;
        voicecrush.repitchMax = 1.5;

        voiceverb.roomSize = 0.95;
        voiceseq.values = [1.55, 1.75, 1.55, 1.9];
        voicegainseq.values = [ 0.6,0,0,0,0,0,0,0,0,0,0 ];
  
        
          ninBassSeq.values = [
            Theory.note(mainchord[0], -2),
            Theory.note(mainchord[1], -2),
            Theory.note(mainchord[0], -2),
            Theory.note(mainchord[0], -2),
            Theory.note(mainchord[1], -2),
            Theory.note(mainchord[0], -2),
            Theory.note(mainchord[0], -2),
            Theory.note(mainchord[1], -2),
          ];

          ninBassGainSeq.values = [0.3, 0.5,0.3,0.0, 0.4, 0.4,0.0];
          ninBass.cutoff = 0.85;
          ninBass.filterMult = 7;

          ethereal.attack = beat * 0.001;
          ethereal2.attack = beat * 0.01;
          ethereal3.attack = beat * 0.00125;
          ethereal4.attack = beat * 0.00125;
    
          ethereal.decay = beat * 10;
          ethereal2.decay = beat * 10;
          ethereal3.decay = beat * 2.5;
          ethereal4.decay = beat * 42;
    
          ethereal.filterMult = 20;
          ethereal2.filterMult = 1;
          ethereal3.filterMult = 56;
          ethereal4.filterMult = 42;

          etherealgainseq.values = [0.25, 0, 0.2, 0, 0, 0.3];
          etherealgainseq2.values = [0.45, 0, 0, 0.2, 0, 0.3, 0, 0];

        etherealseq.values = [  
          Theory.note(mainchord[0], 2),
          Theory.note(mainchord[1], 3),
          Theory.note(mainchord[0], 3),
          Theory.note(mainchord[2], 1),
          Theory.note(mainchord[0], 2),
          Theory.note(mainchord[1], 3),
          Theory.note(mainchord[0], 3),
          Theory.note(mainchord[2], 1),
          Theory.note(mainchord[0], 2),
          Theory.note(mainchord[1], 1),
          Theory.note(mainchord[0], 3),
          Theory.note(mainchord[2], 1),
          Theory.note(mainchord[0], 3),
          Theory.note(mainchord[1], 3),
          Theory.note(mainchord[0], 3),
          Theory.note(mainchord[2], 2) ];

          etherealseq2.values = [
            Theory.note(mainchord[0], 0),
            Theory.note(mainchord[1], 2),
            Theory.note(mainchord[0], 0),
            Theory.note(mainchord[2], 0),
            Theory.note(mainchord[0], 0),
            Theory.note(mainchord[1], 2),
            Theory.note(mainchord[0], 3),
            Theory.note(mainchord[2], 3),
          ];

          etherealseq3.values = [
            Theory.note(mainchord[0], 0),
            Theory.note(mainchord[2], 1),
            Theory.note(mainchord[1], 3),
            Theory.note(mainchord[2], -1),
            Theory.note(mainchord[0], 0),
            Theory.note(mainchord[1], 3),
            Theory.note(mainchord[0], 1),
            Theory.note(mainchord[2], 0),
            Theory.note(mainchord[2], 1),
            Theory.note(mainchord[1], 1),
            Theory.note(mainchord[0], 3),
            Theory.note(mainchord[2], 3),
            Theory.note(mainchord[0], 0),
            Theory.note(mainchord[1], 2),
            Theory.note(mainchord[1], 3),
            Theory.note(mainchord[2], 2),
          ];

          etherealseq4.values = [
            Theory.note(mainchord[2], 2),
            Theory.note(mainchord[0], -2),
            Theory.note(mainchord[0], 3),
            Theory.note(mainchord[2], -2),
            Theory.note(mainchord[0], 0),
            Theory.note(mainchord[1], 0),
            Theory.note(mainchord[2], 3),
            Theory.note(mainchord[2], -1),
        ];

      },
      OnExecute: () => {
        let {beat, uEma} = window;
        //console.log(beat);
        voicecrush.rate = Math.round(beat * 0.125);

        voiceseq.timings = [ beat * 5 ];
        voicegainseq.timings = [ beat * 5 ];

        ninCrush.sampleRate = ninCrushScale(uEma);
        ninBassSeq.timings = [beat * 0.25];
        ninBassGainSeq.timings = [beat * 0.25];
        ninBass.release = beat * 3;
        ninBassSeq.timings = [beat * 0.25];

        delay.delayTime = deltimescale(uEma);
        delay.feedback = delfeedbackscale(uEma);
        delay2.delayTime = deltimescale2(uEma);
        delay2.feedback = delfeedbackscale2(uEma);

        filtcutoffseq.timings = [beat * 0.03125];
        filtqseq.timings = [beat * 0.0156125];
        bitcrushseq.timings = [beat * 0.25];

        bitcrushseq2.timings = [beat * 0.5];
        filtcutoffseq2.timings = [beat * 0.25];
        filtqseq2.timings = [beat * 0.25];

        ethereal.cutoff = cutoffscale(uEma);
        ethereal2.cutoff = cutoffscale2(uEma);
        ethereal3.cutoff = cutoffscale3(uEma);

        verb.roomSize = 0.985;
        //roomscale(uEma);
        //ethereal4.cutoff = cutoffscale4(uEma);
      
        etherealseq.timings = [beat * 0.125];
        //etherealgainseq.timings = [beat * 1];
        etherealgainseq.timings = [beat * 0.125];

        etherealseq2.timings = [beat * 1];
        //etherealgainseq2.timings = [beat * 1];
        etherealgainseq2.timings = [beat * 0.25];

        etherealseq3.timings = [beat * 0.98];
        //etherealgainseq3.timings = [beat * 0.98];
        etherealgainseq3.timings = [beat * 0.125];

        etherealseq4.timings = [beat * 1.01];
        //etherealgainseq4.timings = [beat * 1.01];

        etherealgainseq4.timings = [beat * 0.25];

        etherealcutoffseq4.timing = [beat * 0.5];
        },
    });

    Player.State({
      name: 'Super Fast and Negative',
      instruments: [
        [sampler, voicecrush.input],
        [ninBass,  ninCrush.input ],
        [ninBass],
        [ethereal, delay.input],
        [ethereal2, delay.input],
        [ethereal3, delay2.input],
        [ethereal4, delay2.input],
      ],
      conditions: [() => IsBetween(window.ema, -0.075, -0.065625)],
      OnEnter: function () {
        console.log('test negative');
        Theory.loadScale('astro');
			  mainchord = tonic; 

        cutoffscale = d3
          .scalePow()
          .exponent(0.5)
          .domain([0, 0.1])
          .range([0.01, 0.95]);

          ninCrushScale = d3
          .scalePow()
          .exponent(0.0075)
          .domain([-0.075, -0.065625])
          .range([0.02, 0.7]);

          voicecrush.repitchMin = .5;
          voicecrush.repitchMax = 1.75;

          voiceverb.roomSize = 0.975;
          voiceseq.values = [1.55, 1.75, 1.55, 1.9, 1.55];
          voicegainseq.values = [ 1,0,0,0,0,0 ];
  
        
          ninBassSeq.values = [
            Theory.note(mainchord[0], -2),
            Theory.note(mainchord[1], -2),
            Theory.note(mainchord[0], -2),
            Theory.note(mainchord[0], -2),
            Theory.note(mainchord[1], -2),
            Theory.note(mainchord[0], -2),
            Theory.note(mainchord[0], -2),
            Theory.note(mainchord[1], -2),
          ];

          ninBassGainSeq.values = [0.4, 0.4,0.3,0.0, 0.4, 0.4,0.4];
          ninBass.cutoff = 0.95;
          ninBass.filterMult = 8;

          ethereal.attack = beat * 0.001;
          ethereal2.attack = beat * 0.01;
          ethereal3.attack = beat * 0.00125;
          ethereal4.attack = beat * 0.00125;
    
          ethereal.decay = beat * 2;
          ethereal2.decay = beat * 4;
          ethereal3.decay = beat * 3;
          ethereal4.decay = beat * 4;
    
          ethereal.filterMult = 50;
          ethereal2.filterMult = 1;
          ethereal3.filterMult = 64;
          ethereal4.filterMult = 60;


        etherealseq.values = [  
          Theory.note(mainchord[0], 2),
          Theory.note(mainchord[1], 1),
          Theory.note(mainchord[0], 3),
          Theory.note(mainchord[2], 1),
          Theory.note(mainchord[0], 2),
          Theory.note(mainchord[1], 1),
          Theory.note(mainchord[0], 3),
          Theory.note(mainchord[2], 1),
          Theory.note(mainchord[0], 2),
          Theory.note(mainchord[1], 1),
          Theory.note(mainchord[0], 3),
          Theory.note(mainchord[2], 1),
          Theory.note(mainchord[0], 3),
          Theory.note(mainchord[1], 2),
          Theory.note(mainchord[0], 3),
          Theory.note(mainchord[2], 2) ];

          etherealseq2.values = [
            Theory.note(mainchord[0], 0),
            Theory.note(mainchord[1], 0),
            Theory.note(mainchord[0], 0),
            Theory.note(mainchord[2], 0),
          ];

          etherealseq3.values = [
            Theory.note(mainchord[0], 0),
            Theory.note(mainchord[2], 1),
            Theory.note(mainchord[1], 1),
            Theory.note(mainchord[2], -1),
            Theory.note(mainchord[0], 0),
            Theory.note(mainchord[1], 1),
            Theory.note(mainchord[0], 1),
            Theory.note(mainchord[2], 0),
            Theory.note(mainchord[2], 1),
            Theory.note(mainchord[1], 1),
            Theory.note(mainchord[0], 3),
            Theory.note(mainchord[2], 1),
            Theory.note(mainchord[0], 0),
            Theory.note(mainchord[1], 2),
            Theory.note(mainchord[1], 1),
            Theory.note(mainchord[2], 2),
          ];

          etherealseq4.values = [
            Theory.note(mainchord[2], -1),
            Theory.note(mainchord[0], -2),
            Theory.note(mainchord[0], -1),
            Theory.note(mainchord[2], -2),
            Theory.note(mainchord[0], 0),
            Theory.note(mainchord[1], 0),
            Theory.note(mainchord[2], -3),
            Theory.note(mainchord[2], -1),
        ];

      },
      OnExecute: () => {
        let {beat, uEma} = window;

        voicecrush.rate = Math.round(beat * 0.0625); 

        voiceseq.timings = [ beat * 5 ];
        voicegainseq.timings = [ beat * 5 ];


        ninCrush.sampleRate = ninCrushScale(uEma);
        ninBassSeq.timings = [beat * 0.25];
        ninBassGainSeq.timings = [beat * 0.25];
        ninBass.release = beat * 2;
        ninBassSeq.timings = [beat * 0.25];

        delay.delayTime = deltimescale(uEma);
        delay.feedback = delfeedbackscale(uEma);
        delay2.delayTime = deltimescale2(uEma);
        delay2.feedback = delfeedbackscale2(uEma);

        filtcutoffseq.timings = [beat * 0.03125];
        filtqseq.timings = [beat * 0.0156125];
        bitcrushseq.timings = [beat * 0.25];

        bitcrushseq2.timings = [beat * 0.5];
        filtcutoffseq2.timings = [beat * 0.25];
        filtqseq2.timings = [beat * 0.25];

        ethereal.cutoff = cutoffscale(uEma);
        ethereal2.cutoff = cutoffscale2(uEma);
        ethereal3.cutoff = cutoffscale3(uEma);

        verb.roomSize = roomscale(uEma);
        //ethereal4.cutoff = cutoffscale4(uEma);
      
        etherealseq.timings = [beat * 1];
        //etherealgainseq.timings = [beat * 1];
        etherealgainseq.timings = [beat * 0.125];

        etherealseq2.timings = [beat * 1];
        //etherealgainseq2.timings = [beat * 1];
        etherealgainseq2.timings = [beat * 0.25];

        etherealseq3.timings = [beat * 0.98];
        //etherealgainseq3.timings = [beat * 0.98];
        etherealgainseq3.timings = [beat * 0.125];

        etherealseq4.timings = [beat * 1.01];
        //etherealgainseq4.timings = [beat * 1.01];

        etherealgainseq4.timings = [beat * 0.25];

        etherealcutoffseq4.timing = [beat * 0.5];
        },
    });

    Player.State({
      name: 'Ultra Fast and Negative',
      instruments: [
        [sampler, voicecrush.input],
        [ninBass,  ninCrush.input ],
        [ninBass],
        [ethereal, delay.input],
        [ethereal2, delay.input],
        [ethereal3, delay2.input],
        [ethereal4, delay2.input],
      ],
      conditions: [() => IsBetween(window.ema, -999, -0.075)],
      OnEnter: function () {
        console.log('test negative');
        Theory.loadScale('astro');
			  mainchord = six;

        voicecrush.repitchMin = .25;
        voicecrush.repitchMax = 2;

        voiceverb.roomSize = 0.99;
        voiceseq.values = [1.55, 1.75, 1.55, 1.9, 1.55, 1.9, 3.1, 2.25, 1.75];
        voicegainseq.values = [ 2,0,1.5,0,1.75,0,1,1.5,0,0,0 ];
  

        cutoffscale = d3
          .scalePow()
          .exponent(0.5)
          .domain([0.0, 0.3])
          .range([0.7, 0.95]);

        ninCrushScale = d3
          .scalePow()
          .exponent(0.0075)
          .domain([-0.2, -0.075])
          .range([0.02, 0.7]);
        
          ninBassSeq.values = [
            Theory.note(mainchord[0], -2),
            Theory.note(mainchord[1], -2),
            Theory.note(mainchord[0], -2),
            Theory.note(mainchord[0], -2),
            Theory.note(mainchord[1], -2),
            Theory.note(mainchord[0], -2),
            Theory.note(mainchord[0], -2),
            Theory.note(mainchord[1], -2),
            Theory.note(mainchord[0], -2),
            Theory.note(mainchord[0], -2),
            Theory.note(mainchord[0], -2),
            Theory.note(mainchord[0], -2),
            Theory.note(mainchord[0], -2),
            Theory.note(mainchord[1], -2),
          ];

          ninBassGainSeq.values = [0.35, 0.5,0.3,0.0, 0.4, 0.4,0.45,0.45,0.35,0.0];
          ninBass.cutoff = 0.99;
          ninBass.filterMult = 9;

          ethereal.attack = beat * 0.01;
          ethereal2.attack = beat * 0.01;
          ethereal3.attack = beat * 0.00125;
          ethereal4.attack = beat * 0.00125;
    
          ethereal.decay = beat * 2;
          ethereal2.decay = beat * 2;
          ethereal3.decay = beat * 2;
          ethereal4.decay = beat * 2;
    
          ethereal.filterMult = 20;
          ethereal2.filterMult = 1;
          ethereal3.filterMult = 74;
          ethereal4.filterMult = 60;


        etherealseq.values = [  
          Theory.note(mainchord[0], 2),
          Theory.note(mainchord[1], 1),
          Theory.note(mainchord[0], 3),
          Theory.note(mainchord[2], 3),
          Theory.note(mainchord[0], 2),
          Theory.note(mainchord[1], 1),
          Theory.note(mainchord[0], 3),
          Theory.note(mainchord[2], 1),
          Theory.note(mainchord[0], 2),
          Theory.note(mainchord[1], 1),
          Theory.note(mainchord[0], 3),
          Theory.note(mainchord[2], 3),
          Theory.note(mainchord[0], 3),
          Theory.note(mainchord[1], 2),
          Theory.note(mainchord[0], 3),
          Theory.note(mainchord[2], 2) ];

          etherealseq2.values = [
            Theory.note(mainchord[0], 0),
            Theory.note(mainchord[3], 0),
            Theory.note(mainchord[3], 0),
            Theory.note(mainchord[2], 0),
            Theory.note(mainchord[0], 0),
            Theory.note(mainchord[3], 0),
            Theory.note(mainchord[3], 0),
            Theory.note(mainchord[3], 0),
          ];

          etherealseq3.values = [
            Theory.note(mainchord[0], 0),
            Theory.note(mainchord[2], 1),
            Theory.note(mainchord[1], 1),
            Theory.note(mainchord[2], -1),
            Theory.note(mainchord[0], 0),
            Theory.note(mainchord[1], 1),
            Theory.note(mainchord[0], 1),
            Theory.note(mainchord[2], 0),
            Theory.note(mainchord[2], 1),
            Theory.note(mainchord[1], 1),
            Theory.note(mainchord[0], 3),
            Theory.note(mainchord[2], 1),
            Theory.note(mainchord[0], 0),
            Theory.note(mainchord[1], 2),
            Theory.note(mainchord[1], 1),
            Theory.note(mainchord[2], 2),
          ];

          etherealseq4.values = [
            Theory.note(mainchord[2], -1),
            Theory.note(mainchord[0], -2),
            Theory.note(mainchord[0], -1),
            Theory.note(mainchord[2], -2),
            Theory.note(mainchord[0], 0),
            Theory.note(mainchord[1], 0),
            Theory.note(mainchord[2], -3),
            Theory.note(mainchord[2], -1),
        ];

      },
      OnExecute: () => {
        let {beat, uEma} = window;

        voicecrush.rate = Math.round(beat * 0.03125);
        voiceseq.timings = [ beat * 5 ];
        voicegainseq.timings = [ beat * 5 ];



        ninCrush.sampleRate = ninCrushScale(uEma);
        ninBassSeq.timings = [beat * 0.25];
        ninBassGainSeq.timings = [beat * 0.25];
        ninBass.release = beat * 1.5;
        ninBassSeq.timings = [beat * 0.25];
        
        // ethereal.decay = beat * 0.001;
        // ethereal2.decay = beat * 0.001;
        // ethereal3.decay = beat * 0.001;
        // ethereal4.decay = beat * 0.001;


        delay.delayTime = deltimescale(uEma);
        delay.feedback = delfeedbackscale(uEma);
        delay2.delayTime = deltimescale2(uEma);
        delay2.feedback = delfeedbackscale2(uEma);

        filtcutoffseq.timings = [beat * 0.125];
        filtqseq.timings = [beat * 0.25];
        bitcrushseq.timings = [beat * 0.25];

        bitcrushseq2.timings = [beat * 0.5];
        filtcutoffseq2.timings = [beat * 0.25];
        filtqseq2.timings = [beat * 0.25];

        ethereal.cutoff = cutoffscale(uEma);
        ethereal2.cutoff = cutoffscale2(uEma);
        ethereal3.cutoff = cutoffscale3(uEma);

        verb.roomSize = roomscale(uEma);
        //ethereal4.cutoff = cutoffscale4(uEma);
      
        etherealseq.timings = [beat * 1];
        //etherealgainseq.timings = [beat * 1];
        etherealgainseq.timings = [beat * 0.125];

        etherealseq2.timings = [beat * 1];
        //etherealgainseq2.timings = [beat * 1];
        etherealgainseq2.timings = [beat * 0.25];

        etherealseq3.timings = [beat * 0.98];
        //etherealgainseq3.timings = [beat * 0.98];
        etherealgainseq3.timings = [beat * 0.125];

        etherealseq4.timings = [beat * 1.01];
        //etherealgainseq4.timings = [beat * 1.01];

        etherealgainseq4.timings = [beat * 0.25];

        etherealcutoffseq4.timing = [beat * 0.5];
        },
    });

  }
}

export { MFTVIICollider };
