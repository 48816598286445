const axios = require( 'axios');

let ema = 0;
let prices = [];

let busy = false;
let data = {};

setInterval(() => {
  if (busy) return;
  busy = true;
  let req = axios
    .get('https://mooncowboy.onrender.com/moon/ETH/ema')
    .then((res) => {
      ema = res.data.ema;
      let price = res.data.prices[res.data.prices.length - 1];
      prices = res.data.prices;
      // console.log(ema);
      data = { ema: ema, price: price, prices: prices };
      busy = false;
    })
    .catch((e) => {
      console.log(`${e}`)
      busy = false;
    });
}, 10);

module.exports = {
  data: () => {return data}
}