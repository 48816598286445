function isConnected(instrument, target) 
{
	return target?.inputs?.includes(instrument);
}

function safeConnect(instrument, target = null, level = 1)
{
	if (target !== null && target.inputs)
	{
		if (!target.inputs.includes(instrument))
		{
			instrument.connect(target, level);
		}
	}
	else if (target === null) {
		instrument.connect();
	}
	
}

function connectAll(list)
{
	for (let instrument of list)
	{
		safeConnect(instrument[0], instrument[1] || null, instrument[2] || 1);
	}
}

function safeDisconnect(instrument, target = null)
{
	if (target !== null && target.inputs)
	{
		if (target.inputs.includes(instrument))
		{
			instrument.disconnect(target);
		}
	}
	else if (target === null) {
		instrument.disconnect();
	}
}

function disconnectAll(list)
{
	for (let instrument of list)
	{
		safeDisconnect(instrument[0], instrument[1] || null);
	}
}

class Music {
	
	static theory = null;
	static chord = null;

	static setTheory(theory) {
		Music.theory = theory;
    console.log('theory set')
	}

	static setChord(newCurrent) {
		Music.chord = newCurrent;
	}

  static loadScale(scale) {
    Music.theory.loadScale(scale);
  }

}

function Notes(...params)
{
	let notes = [];
	for(let arg of params)
	{
		notes.push(Music.theory.note(Music.chord[arg[0]],arg[1]));
	}
	return notes;
}

export { isConnected, safeConnect, safeDisconnect, connectAll, disconnectAll, Music, Notes }