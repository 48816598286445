import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import SceneSubject from '../sceneSubject';
import * as Util from '../../utilities/utilities';
import { lerp } from '../../utilities/utilities';
import { readFileSync } from 'fs';
import {vertex, fragment} from '../shaders/pointCloud.shader';

export default class CalgaryCloud extends SceneSubject {

  constructor(scene) {
    super(scene);
    this.start = Date.now() - 1000 * Math.random();
    this.gltfLoader = new GLTFLoader();
    this.load();
  }

  async load() {
    this.gltfLoader.load(`calgary_canada/scene.gltf`, (loaded) => {
      let scale = 5;
      this.gltfScene = loaded.scene;
      this.model = this.gltfScene.children[0];
      this.gltfScene.scale.set(scale, scale, scale);
      this.gltfScene.rotation.x = 90;
      this.gltfScene.position.x = -25;
      this.gltfScene.position.y = 25;
      this.gltfScene.position.z = -100;
      // this.gltfScene.position.y = -100;
      let pos = loaded.scene.position;
      this.movePos = new THREE.Vector3(pos.x,pos.y,pos.z);
      this.model.position.z = 5;
      console.log(this.model);

      this.material = new THREE.ShaderMaterial({
        uniforms: {
          color: { value: new THREE.Color(0x9a8a8a) },
          time: {
            // float initialized to 0
            type: 'f',
            value: 0.0,
          },
          ema: {
            // float initialized to 0
            type: 'f',
            value: 0.0,
          },
          ema2: {
            // float initialized to 0
            type: 'f',
            value: 0.0,
          },
          pointSize: {
            type: 'f',
            value: 12.0
          }
        },
        vertexShader: vertex,
        fragmentShader: fragment,
      });

      this.gltfScene.traverse((o) => {
        if (o.material) o.material = this.material;
        if (o instanceof THREE.Points && o.geometry.attributes.color) {
          const color = new THREE.Color(0xdededed3);
          let colors = o.geometry.attributes.color.array;
          let customColors = new Float32Array(
            o.geometry.attributes.color.array.length,
          );
          for (let i = 0; i < colors.length; i += 4) {
            customColors[i] = colors[i];
            customColors[i + 1] = colors[i + 1];
            customColors[i + 2] = colors[i + 2];
            customColors[i + 3] = colors[i + 3];
          }
          o.geometry.setAttribute(
            'customColor',
            new THREE.BufferAttribute(customColors, 4),
          );
        }
      });
      // for(let point of this.model.children)
      // {
      //   console.log(point)
      //   point.material = this.material;
      // }
      this.rotationObject = this.gltfScene;

      this.rotationObject.rotation.y += 360 * Math.random();

      this.scene.add(this.gltfScene);
      window.gltfScene = this.gltfScene;

    });
  }

  update() {
    let { rotationObject, start } = this;

    let reaction = 0.8*(window.ema * 8);// + ((window.busHigh?.output * 2) || 0) + ((window.kickFollow?.output) || 0) + ((window.busSecond?.output) || 0) + ((window.fmFollow?.output) || 0) + ((window.synthFollow?.output) || 0));
    // this.reaction = lerp(reaction, this.reaction || 0, 0.1);
    // reaction = this.reaction;

    if (this.gltfScene || rotationObject) {
      if (!rotationObject) rotationObject = this.gltfScene;
      rotationObject.rotation.y += 0.001 + Math.abs((reaction || 1) * 0.0025);
      if (Math.abs(window.ema) > 0.078)
        rotationObject.rotation.z += Math.abs((reaction || 1) * 0.000001);
      else
        rotationObject.rotation.z = lerp(rotationObject.rotation.z, 0, 0.01);
    }
    this.material.uniforms['time'].value = (0.0000025 * (Date.now() - start));
    this.material.uniforms['ema'].value = reaction;
    this.material.uniforms['ema2'].value = (window.ema);
    if (window.mouseEvent && !window.mouseEvent.left)
    {
      let bounds = 200;
      let sensitivity = .008;
      let speed = 0.1;
      let dir = new THREE.Vector2(
        window.mouseEvent.pageX - window.innerWidth/2,
        window.mouseEvent.pageY - window.innerHeight/2
      );
      const clamp = (num, min, max) => Math.min(Math.max(num, min), max);
      let moveX = clamp(this.rotationObject.position.x - dir.x * sensitivity, this.movePos.x - bounds, this.movePos.x + bounds);
      let moveY = clamp(this.rotationObject.position.y - -dir.y * sensitivity, this.movePos.y - bounds, this.movePos.y + bounds);
      let move = new THREE.Vector3(
        lerp(this.rotationObject.position.x, moveX, speed), 
        lerp(this.rotationObject.position.y, moveY, speed),
        lerp(this.rotationObject.position.z, this.movePos.z, speed));
      this.rotationObject.position.set(move.x, move.y, move.z);
    }
  }

  async getObject3D() {
    return new Promise(async (resolve) => {
      while (!this.rotationObject) {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        if (this.rotationObject)
        {
          resolve(this.rotationObject);
        }
      }
    })
  }

}
