import * as THREE from 'three';

export default class SceneSubject {

  scene = null;

  constructor(scene) {
    this.scene = scene;
  } 
  
  update(time) { 
    // do something
  }

  async getObject3D() {
    
  }

}

