import * as Utils from '../utilities/utilities';
import { AudioMachine, AudioState, IsBetween } from './audioMachine';
import { isConnected, safeConnect, safeDisconnect, connectAll, disconnectAll, Music, Notes,
} from './mftTools';

class MasterCollider {
  colliders = [];
  beat = 44100;
  ema = 0;
  uEma = 0;
  price = 0;

  constructor(Gibberish, d3, Tune, emaData) {
    this.Gibberish = Gibberish;
    this.d3 = d3;
    this.Tune = Tune;
    this.emaData = emaData;
    this.Player = new AudioMachine(Gibberish.scheduler);
    this.Theory = new Tune();
    this.Music = Music;
    Music.setTheory(this.Theory);
    this.Theory.tonicize(110);

    window.mastercollider = () => this.Update();
    window.master = this;

    this.beatscale = d3
      .scalePow()
      .exponent(0.05)
      .domain([0, 0.3])
      .range([441000, 8820]);


   
  }

  Update() {
    let { ema, uEma } = this;
    let data = this.emaData.data();
    let speed = window.testEma ? window.testEmaSpeed || 0.1 : 0.4;
    ema = Utils.lerp(
      ema || 0,
      (window.testEma ? window.testEma : data?.ema) || 0,
      speed,
    );
    uEma = Math.abs(ema);
    this.ema = window.ema = ema || 0;
    this.uEma = window.uEma = uEma || 0;
    this.price = window.price = Utils.lerp(window.price || 0, data.price, 0.4);

    if (window.logEma) console.log(ema);
    
  }

  UpdatePlayer() {
    let { uEma, beatscale } = this;
    this.beat = window.beat = beatscale(uEma);
    this.Player.Update();
  }

  AddCollider(collider) {
    if (!this.colliders.includes(collider)) {
      collider.SetMaster(this);
      this.colliders.push(collider);
      collider.Init();
    }
  }
}

class Collider {

  SetMaster(master) {
    this.master = master;
  }

  Init() {
    // setup instruments and shit
  }
}

export { MasterCollider, Collider };
