import React, { Component } from 'react';
import Eth from '../../eth/eth';
import './search.css';

let rnd = '' + Math.random();

export default class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: '0x2CA4E64aa2A0bDFE13dFC8c7efec722E0960F9C9',
    };
  }

  searchRef = React.createRef();

  componentDidMount() {
    this.searchRef.current.focus();
  }

  IsValidSearch(address) {
    if (Eth.isValidAddress(address))
    {
      return true;
    }
    return false;
  }

  OnClick(e) {}

  OnChange(e) {
    this.setState({ search: e.target.value });
  }

  OnKeyPress(e) {
    if (e.key === 'Enter' && this.IsValidSearch(this.state.search))
    {
      this.props.onSearch(this.state.search);
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className={'SearchModal'}>
          <div className={'Search'}>
            <form autoComplete={rnd} onSubmit={(e) => e.preventDefault()}>
              <input
                className="Search-input"
                ref={this.searchRef}
                onChange={(e) => this.OnChange(e)}
                onKeyPress={(e) => this.OnKeyPress(e)}
                onClick={(e) => this.OnClick(e)}
                autoComplete={rnd}
                spellCheck="false"
                value={this.state.search}
                disabled={false}
                placeholder={'0xaddress'}
              />
              <div className="Search-decoration"/>
            </form>
            <div className="Search-message">{this.props.searchMessage}</div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}