import React, { Component, createRef } from 'react';
import './App.css';
import * as d3 from 'd3';
import { MasterCollider } from './collider/mastercollider.js';
import { TestCollider } from './collider/mftColliders/testCollider.js';
import { Horsekick } from './collider/mftColliders/horsekick.js';
import Tune from './tune.js';
import ThreeContainer from './components/threeContainer';
import ema from './collider/ema';
import VolumeSlider from './components/volumeSlider/volumeSlider';
import Search from './components/search/search';
import Eth from './eth/eth';
import * as workerTimers from 'worker-timers';
import { MFTVIICollider } from './collider/mftColliders/mftvii';

let init = false;

const memsize = 44100 * 60 * 20;

class App extends Component {

  appRef = createRef();

  constructor(props) {
    super(props);
    this.state = {price: 0, playing: false, loading: false, mfts:[], searchMessage: "", hideCursor: false, fullscreen: false};
  }

  toggleDebug() {
    let isDebug = window.localStorage.getItem("debug") === "true";
    if (isDebug !== true)
    {
      window.localStorage.setItem("debug", "true");
      this.start();
      return "Debug mode on";
    }
    else
    {
      window.localStorage.setItem("debug", "false")
      return "Debug mode off";
    }
  }

  componentDidMount() {
    window.debug = () => this.toggleDebug();
    const Gibberish = window.Gibberish;
    Gibberish.workletPath = './gibberish_worklet.js';
    if (window.renderer) this.renderer = window.renderer;
    Gibberish.init(memsize).then(() => {
      console.log(`Gibberish Loaded with ${memsize} memory.`)
      init = true;
      this.proxy = {};
      Gibberish.export(this.proxy);
      console.log(this.proxy);
      this.masterCollider = new MasterCollider(this.proxy, d3, Tune, ema);
      if (window.localStorage.getItem("debug") === "true") this.start();
    }).catch((err) => {
      // console.error(err);
    });
    window.document.addEventListener("keydown", (e) => this.handleKeyDown(e));
    window.document.addEventListener("mousemove", (e) => this.handleMouseMove(e));
    window.document.addEventListener("mouseleave", (e) => this.handleMouseLeave(e));
  }

  colliders = [
    // new TestCollider(),
    //new Horsekick(),
    new MFTVIICollider(),
    // testje
  ]

  start() {
    // trigger render update once before we remove the search modal 
    this.renderer(0); 
    this.setState({playing: true, searchMessage: ""});
    console.log(window);
    if (init) {
      this.lastMoved = Date.now();
      for(let c of this.colliders)
      {
        this.masterCollider.AddCollider(c);
      }
      window.requestAnimationFrame((time) => this.updateRenderer(time));
      this.update = workerTimers.setInterval(() => this.masterCollider.Update(), 50);
      workerTimers.setInterval(() => this.masterCollider.UpdatePlayer(), 500);
    }
  }

  updateRenderer(time) {
    this.renderer(time);
    this.updatePrice();
    this.updateMouse();
    window.requestAnimationFrame((time) => this.updateRenderer(time));
  }

  updatePrice() {
    this.setState({price:window.price || 0});
  }

  updateMouse() {
    if (Date.now() - this.lastMoved > 8000)
    {
      if (!this.state.hideCursor)
      {
        this.setState({hideCursor: true});
      }
    }
    else
    {
      if (this.state.hideCursor)
      {
        this.setState({hideCursor: false});
      }

    }
  }

  async onSearch(address) {
    let launch = false;
    this.setState({loading: true, searchMessage: "Scanning for MFTs"});
    // check if user has MFTs
    console.log('woop');
    // give feedback to element if not
    launch = await Eth.searchAddress(address);
    // else load shit and start playing
    if (launch)
    {
      // user has MFTs
      this.start();
      let data = await Eth.listMFTs(address);
      console.log(data);
      this.state.mfts.push(data);
      this.setState({mfts:this.state.mfts});
      console.log(this.state.mfts);
    }
    else {
      // user doesn't have MFTs
      this.setState({loading: false,searchMessage: "Address does not have any MFTs"});
      setTimeout(() => {this.setState({searchMessage: ""});}, 8000);
    }
  }

  handleMouseMove(e){
    if (this.state.playing)
    this.lastMoved = Date.now();
    window.mouseEvent = e;
  }

  handleMouseLeave(e) {
    window.mouseEvent.left = e;
  }

  handleKeyDown(e){
    if (e.key === 'f' && this.state.playing)
    {
      if (this.state.fullscreen)
      {
        if (window.document.fullscreenElement) window.document.exitFullscreen();
        this.setState({fullscreen: false});
      }
      else {
        // this.appRef.current.requestFullScreen();
        let container = window.document.getElementById('App');
        if (container.requestFullscreen) container.requestFullscreen();
        else if (container.msRequestFullscreen) container.msRequestFullscreen();
        else if (container.mozRequestFullScreen) container.mozRequestFullScreen();
        else if (container.webkitRequestFullscreen) container.webkitRequestFullscreen();
        this.setState({fullscreen: true});
      }
    }
    if (e.key === "Escape")
    {
      if (this.state.fullscreen)
      {
        if (window.document.fullscreenElement) window.document.exitFullscreen();
        this.setState({fullscreen: false});
      }
    }
  }

  render() {

    let { searchMessage, hideCursor } = this.state;

    const playing = this.state.playing ? ' playing' : '';
    const loading = this.state.loading ? ' loading' : '';
    const hidecursor = hideCursor ? ' hideCursor' : '';

    return (
      <div id="App" className={"App" + playing + loading + hidecursor} ref={this.appRef}>
        <ThreeContainer />
        <header className="App-header">
          <div className="App-text">
            Music For Traders
            <div className="App-numberage">
              {this.state.price.toFixed(4)} Ξ{' '}
              {window.ema?.toFixed(8) || (0.0).toFixed(8)}
            </div>
          </div>
          <div className="App-volume">
            <VolumeSlider />
          </div>
          <div className="App-mfts">
            {this.state.mfts.map( (e,i) => (
              <span key={i}>{e.name}</span>
            ))}
          </div>
        </header>
        <Search onSearch={(address) => this.onSearch(address)} searchMessage={searchMessage}/>
      </div>
    );
  }
}

export default App;

