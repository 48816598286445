module.exports = {
  vertex: `
  attribute float scale;
  attribute vec3 customColor;

  varying vec3 vColor;

  void main() {

    vColor = customColor;

    vec4 mvPosition = modelViewMatrix * vec4( position, 1.0 );

    gl_PointSize = scale * ( 300.0 / -mvPosition.z );

    gl_Position = projectionMatrix * mvPosition;

  }
  `,
  fragment: `
  uniform vec3 color;

  varying vec3 vColor;

  void main() {

    if ( length( gl_PointCoord - vec2( 0.5, 0.5 ) ) > 0.475 ) discard;

    gl_FragColor = vec4( color * vColor, 1.0 );

  }
  `
};