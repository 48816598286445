import * as THREE from 'three';
import wireSphere from './subjects/wireSphere';
import cubeGrid from './subjects/cubeGrid';
import pointCloud from './subjects/pointCloud';
import bufferGeo from './subjects/bufferGeo';
import pointCloudModel from './subjects/pointCloudModel';
import squareCircle from './subjects/squareCircle';
import {ShaderPass} from 'three/examples/jsm/postprocessing/ShaderPass.js'
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { SavePass } from 'three/examples/jsm/postprocessing/SavePass.js';
import { CopyShader } from 'three/examples/jsm/shaders/CopyShader.js';
import { BlendShader } from 'three/examples/jsm/shaders/BlendShader.js';
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js';
import RoomModel from './subjects/roomModel';
import CalgaryCloud from './subjects/calgaryCloud';
import AsciiLetters from './subjects/asciiLetters';

export default class SceneManager {

  constructor(canvas) {
    let width = canvas.width;
    let height = canvas.height;
    this.screenDimensions = { width, height };

    this.scene = this.buildScene();
    this.renderer = this.buildRender(this.screenDimensions, canvas);
    this.camera = this.buildCamera(this.screenDimensions);
    this.composer = this.buildComposer(this.renderer, this.scene, this.camera);
    this.sceneSubjects = this.createSceneSubjects(this.scene);
  }

  buildScene() {
    let scen = new THREE.Scene();
    scen.fog = new THREE.Fog( 0x000000, 1, 15000 );
    return scen;
  }

  buildRender({ width, height }, canvas) {
    let ren = new THREE.WebGLRenderer({ antialias: false, canvas: canvas });
    ren.setPixelRatio(1);
    ren.setSize(width*1.5, height*1.5, false);
    ren.clearColor = '0x00ff44';
    ren.toneMapping = THREE.ReinhardToneMapping;
    return ren;
  }

  buildComposer(renderer, scene, camera) {
    const composer = new EffectComposer( renderer );
    const renderPass = new RenderPass( scene, camera );
    const waterTarget = new THREE.WebGLRenderTarget(this.screenDimensions.width * 1.5, this.screenDimensions.height * 1.5);
    waterTarget.depthBuffer = true
    waterTarget.depthTexture = new THREE.DepthTexture()
    composer.addPass( renderPass );
    const params = {
      exposure: 2,
      bloomStrength: 0.5,
      bloomThreshold: 0.2,
      bloomRadius: 0.15
    };
    const bloomPass = new UnrealBloomPass( new THREE.Vector2( this.screenDimensions.width * 1.5, this.screenDimensions.height * 1.5), 1.5, 0.4, 0.85 );
    bloomPass.exposure = params.exposure;
    bloomPass.threshold = params.bloomThreshold;
    bloomPass.strength = params.bloomStrength;
    bloomPass.radius = params.bloomRadius;
    composer.addPass( bloomPass );
    const renderTargetParameters = {
      minFilter: THREE.LinearFilter,
      magFilter: THREE.LinearFilter,
      stencilBuffer: false
    };
    
    // save pass
    const savePass = new SavePass(
      new THREE.WebGLRenderTarget(
        this.screenDimensions.width * 1.5, 
        this.screenDimensions.height * 1.5,
        renderTargetParameters
      )
    );
    
    // blend pass
    const blendPass = new ShaderPass(BlendShader, "tDiffuse1");
    blendPass.uniforms["tDiffuse2"].value = savePass.renderTarget.texture;
    blendPass.uniforms["mixRatio"].value = 0.31;
    
    // output pass
    const outputPass = new ShaderPass(CopyShader);
    outputPass.renderToScreen = true;
    
    // adding passes to composer
    composer.addPass(blendPass);
    composer.addPass(savePass);
    composer.addPass(outputPass);
    return composer;
  }

  buildCamera({ width, height }) {
    const cam = new THREE.PerspectiveCamera(75, width / height, 0.01, 1000);
    cam.position.z = 15;
    return cam;
  }

  createSceneSubjects(scene) {
    scene.add(new THREE.AmbientLight(0xffffff, 0.5));

    const dirLight = new THREE.DirectionalLight(0xffffff, 1);
    dirLight.position.set(-5, 10, 7.5);
    dirLight.castShadow = true;
    dirLight.shadow.camera.right = 2;
    dirLight.shadow.camera.left = -2;
    dirLight.shadow.camera.top = 2;
    dirLight.shadow.camera.bottom = -2;

    dirLight.shadow.mapSize.width = 1024;
    dirLight.shadow.mapSize.height = 1024;
    scene.add(dirLight);
    this.dirLight = dirLight;

    const pointLight = new THREE.PointLight( 0xff2200 );
				pointLight.position.set( 6, 1, -6 );
				scene.add( pointLight );
    this.createSceneSubjectsAsync(scene);
    return [new AsciiLetters(scene), new AsciiLetters(scene, 0.5)]//new squareCircle(scene)]//new wireSphere(scene)];//new wireSphere(scene)];//, new cubeGrid(scene)];
  }

  async createSceneSubjectsAsync(scene) {
    let toilet = new CalgaryCloud(scene);
    // let toilet = new RoomModel(scene);
    let cloud = new pointCloud(scene);
    let toiletObject3D = await toilet.getObject3D();
    let cloudObject3D = await cloud.getObject3D();
    toiletObject3D.attach(cloudObject3D);
    window.cloud = cloudObject3D;
    this.sceneSubjects.push(toilet,cloud);
    console.log(toiletObject3D);
  }

  update(time) {
    for(let i = 0; i < this.sceneSubjects.length; i++) {
      this.sceneSubjects[i].update(time);
    }
    if (!this.composer)
      this.renderer.render(this.scene, this.camera);
    else
    {
      this.composer.render();
    }
  }

  onWindowResize(canvas) {
    const { width, height } = canvas;
    this.screenDimensions.width = width;
    this.screenDimensions.height = height;
    this.camera.aspect = width / height;
    this.camera.updateProjectionMatrix();
    this.renderer.setSize(width*1.5, height*1.5, false);
    this.composer.setSize(width*1.5, height*1.5);
  }
}
