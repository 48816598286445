import React, { Component } from 'react';
import './volumeSlider.css';

export default class VolumeSlider extends Component {

  constructor(props) {
    super(props);
    this.state = {volume: 100};
  }
  
  componentDidMount() {
    window.requestAnimationFrame(() => this.setGibberish());
    let vol = window.localStorage.getItem('mft-volume');
    if (vol) {
      this.setState({volume:parseFloat(vol)});
    }
  }

  onValueChange(event) {
    this.setState({volume:event.target.value});
    window.localStorage.setItem('mft-volume', event.target.value.toString());
  }

  setGibberish(){
    window.requestAnimationFrame(() => this.setGibberish());
    if (window.Gibberish.output)
    {
      window.Gibberish.output.gain = this.state.volume/100;
    }
  }

  render() {
    return <div className="VolumeSlider"><input type="range" onChange={e => this.onValueChange(e)} min="0" max="100" value={this.state.volume}/></div>;
  }
}
