import * as THREE from 'three';
import SceneSubject from '../sceneSubject';
import * as Util from '../../utilities/utilities';
import { fragment, vertex } from '../shaders/colorDot.shader';

export default class pointCloud extends SceneSubject {

  particleCount = 30000;
  particles = [];
  sizes = [];
  randomizer = [];
  randomizer2 = [];
  size = 20;

  constructor(scene) {
    super(scene);

    const sprite = new THREE.TextureLoader().load( 'logo192.png' );

    let { material, particles, size, particleCount, geometry, vertexShader, fragShader, sizes, colors, randomizer, randomizer2 } = this;

    geometry = new THREE.BufferGeometry();
    const positions = new Float32Array( particleCount);
    const scales = new Float32Array( particleCount );
    colors = new Float32Array( particleCount );
    const color = new THREE.Color( 0xdedede );

    for (let i = 0; i < particleCount; i++) {

      let vector = new THREE.Vector3();
      vector.x = Math.random() * 1000 - 500;
      vector.y = Math.random() * 1000 - 500;
      vector.z = Math.random() * 3000 - 1500;
      scales[i] = Math.random() * size;
      sizes[i] = scales[i];
      randomizer[i] = Math.random() * 100 + 1;
      randomizer2[i] = Math.random() * 100 + 1;
      color.setHSL( Math.abs(vector.y)/100, 0.7, 0.5 );

      vector.toArray(particles, i*3);
      vector.toArray(positions, i*3);
      color.toArray( colors, i * 3 );
    }
    geometry.setAttribute( 'position', new THREE.Float32BufferAttribute( positions, 3 ) );
    geometry.setAttribute( 'scale', new THREE.BufferAttribute( scales, 1 ) );
    geometry.setAttribute( 'customColor', new THREE.BufferAttribute( colors, 3 ) );

    // material = new THREE.PointsMaterial( { size: size, sizeAttenuation: true, alphaTest: 0.5, transparent: true } );
    // material.color.setHSL( 1.0, 0.3, 0.7 );
    material = new THREE.ShaderMaterial( {

      uniforms: {
        color: { value: new THREE.Color( 0xffffff ) },
      },
      vertexShader: vertex,
      fragmentShader: fragment

    } );
    this.cloud = new THREE.Points(geometry, material);
    this.cloud.rotation.x = Math.random() * 6;
    this.cloud.rotation.y = Math.random() * 6;
    this.cloud.rotation.z = Math.random() * 6;
    // this.cloud.position.x = this.cloud.position.x - 250;
    // this.cloud.position.y = this.cloud.position.y - 250;
    this.cloud.position.z = this.cloud.position.z - 200;
    scene.add(this.cloud);
  }

  async getObject3D() {
    return this.cloud;
  }

  update(time) {
    let { particles, particleCount, cloud, sizes, randomizer, randomizer2 } = this;
    let react = Math.abs(window.ema) * 8 + ((window.karplusFollow?.output * 2) || 0) + ((window.fmFollow?.output) || 0) + ((window.synthFollow?.output) || 0);
    if (cloud)
    {
      const vertices = cloud.geometry.attributes.position.array;
      const scales = cloud.geometry.attributes.scale.array;
      const colorAttr = cloud.geometry.attributes.customColor.array;
      const color = new THREE.Color( 0xdedede );

      for (let i = 0; i < particleCount; i++) {
        let rnd = randomizer[i];
        let rnd2 = randomizer2[i];
        let max = rnd * 1000 + 10;
        let max2 = rnd2 * 1000 + 10;
        vertices[i] = particles[i] + Math.sin((time+i)/max)*1000*react;
        vertices[i+1] = particles[i+1] + Math.sin((time+i)/max2)*1000*react;
        vertices[i+2] = particles[i+2] + Math.sin((time+i)/max)*1000*react;
        scales[i] = sizes[i] * react * 0.8;
        // yeah fuck me but this is a lerp to get the red color
        color.setHSL( Util.lerp(0,Math.abs(vertices[i+1])/100,Math.max(0,window.ema*10)), 0.7, 0.5);
        let c = color.toArray();
        colorAttr[i*3] = c[0];
        colorAttr[i*3+1] = c[1];
        colorAttr[i*3+2] = c[2];
      }
      cloud.geometry.attributes.position.needsUpdate = true;
      cloud.geometry.attributes.scale.needsUpdate = true;
      cloud.geometry.attributes.customColor.needsUpdate = true;
    }
  }
}
