import axios from 'axios';
import * as ethers from 'ethers';
const { utils } = ethers;
const abi = require('./MusicForTraders.json');

const provider = ethers.getDefaultProvider('goerli', 
{ 
  alchemy: 'Zq1Rc6XIhn-_BA3iObn8q_1LD0nYKogX', 
  etherscan: '1AF7RTY21XP6PISE5NPVA5ZS6AZCRXM3IF', 
  infura: '55ace0e7c70b488b95accde27739d275' 
});

const contractAddress = '0x5C720af3444eC9e59F4a2C7bc6bC62645908bb9c';
const contract = new ethers.Contract(contractAddress, abi.abi, provider);



class Eth {

  currentAddress = "";

  async searchAddress(address) {
    // check if address
    console.log(address);
    if (utils.isAddress(address))
    {
      address = await ethers.utils.getAddress(address);
      console.log(address);
    }
    let tries = 3
    while (tries > 0)
    {
      tries--;
      try {
        let balance = await contract.balanceOf(address, 1);
        console.log(balance);
        let hasBalance = balance > 0;
        console.log(hasBalance)
        return hasBalance;
      }
      catch(e) {
        console.warn(e);
      }
      
    }
    return Promise.reject();
  }

  isValidAddress(address) {
    if (utils.isAddress(address))
    {
      return true;
    }
    return false;
  }

  async listMFTs(addresss) {
    let tries = 3;
    while (tries > 0)
    {
      tries--;
      try {

        let data = await contract.uri(1);
        let response = await axios.get(data);
        console.log(response);
        let mft = response.data;
        return mft;
      } catch(e) {
        console.warn(e);
      }
    }
    return Promise.reject();
  }

}

const instance = new Eth();

export default instance;